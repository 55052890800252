import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import '../dashboard/css/dashboard.css'
import { Outlet } from 'react-router-dom';
import { doLogin, isLoggedIn } from '../../auth/auth';
import { useNavigate, Navigate } from 'react-router-dom';
import ReLoginModal from '../commons/ReLoginModal';
import { useState } from 'react';
import { getUserDistrict, getUserRole,getOfficeLocation } from '../../services/user-details';
import { useEffect } from 'react';
import axiosProtected from '../../helper/axios_authenticated_urls';
import requests from '../../helper/request';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const Dashboard = () => {

    const [showModal, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let districtCode = getUserDistrict();
     let districtName = getOfficeLocation();
    // console.log("District code" +districtCode)
    let userRole = getUserRole()[0];
    // console.log(userRole+"userRole")

    return (
        isLoggedIn() ?
            <>
                <div className='container'>

                    <ReLoginModal showModal={showModal} setShow={setShow} handleClose={handleClose}  ></ReLoginModal>
                    <Outlet context={[showModal, setShow, districtCode, userRole,districtName]}></Outlet>
                </div>

            </> : <Navigate to={"/"}></Navigate>
    )

}

Dashboard.propTypes = propTypes;
Dashboard.defaultProps = defaultProps;
// #endregion

export default Dashboard;