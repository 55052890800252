import { isLoggedIn } from "../auth/auth"

export const getCurrentUser = () => {
  if (isLoggedIn) {
    return JSON.parse(localStorage.getItem("data")).user
  } else {
    return false
  }
}

export const getUserRole = () => {
  if (isLoggedIn) {
    return JSON.parse(localStorage.getItem("data")).user.roles[0].name
  } else {
    return false
  }
}

export const getUserDistrict = () => {
  if (isLoggedIn) {
    return JSON.parse(localStorage.getItem("data")).user.districtId
  } else {
    return false
  }
}

export const getUserName = () => {
  if (isLoggedIn && localStorage.getItem("data") != undefined) {
    return JSON.parse(localStorage.getItem("data")).user.userName
  } else {
    return false
  }
}

export const getOfficeLocation = () => {
  if (isLoggedIn && localStorage.getItem("data") != undefined) {
    return JSON.parse(localStorage.getItem("data")).user.officeLocation
  } else {
    return false
  }
}
