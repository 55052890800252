import React from 'react'
import { isLoggedIn } from "../auth/auth";
import { getUserRole } from '../services/user-details';
import { Outlet,Navigate } from 'react-router-dom'

function SuperAdminRoutes() {
  return (
    <> {isLoggedIn() && getUserRole()==="SUPER_ADMIN"?<Outlet></Outlet>:<Navigate to="/"></Navigate>}
    </>
   
  )
}

export default SuperAdminRoutes