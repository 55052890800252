import React from 'react';

// import PropTypes from 'prop-types';

import emblem from '../../../src/images/emblem-dark.png'
import logo from '../../../src/images/logo/DEPTLOGO.png'
import facebook from '../../images/facebook.svg'
import { Col, Row } from 'react-bootstrap';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const Header = () => {
	return <>
		<div className='container-fluid'>
			{/* Emblem
			<div className='row'>
				<div className="clearfix d-flex justify-content col-md-6 col-sm-12" id="b-header">
					<div className="float-left d-flex h-100">
						<img src={emblem} className="align-self-center img-fluid b-emblem-image" title="National Emblem of India" alt="emblem of india logo"></img>
					</div>
					<div className="float-left d-flex h-100">
						<strong className="align-self-center pl-3 mx-3"><span>Government of Meghalaya</span> <br></br><span>Fisheries Department</span></strong>
					</div>
				</div>
				<div className='col-md-6 col-sm-12 d-flex justify-content-end align-self-center text-end text-uppercase pt-4  d-md-flex'>

					<strong className='mt-5'><em><span>Meghalaya Fish<br /> Farmer Portal</span></em></strong>
					<img src={logo} width="120" alt='' height="120" className='my-auto mb-3 img-fluid '></img>
				</div>
			</div> */}
			<Row>
				<Col md={9} className="d-flex align-items-center">
					<a
						target="_blank"
						href="https://meghalaya.gov.in/"
						rel="noopener noreferrer"
						title="Government of Meghalaya, External Link that opens in a new window"
					>
						<img
							src={emblem}
							alt="Government Emblem"
							width={70}
							className="d-none d-md-block"
						/>
					</a>
					<div className="mx-3 d-none d-md-block fs-5">
						<strong>
							<span>Government of Meghalaya</span>
							<br />
							<span>Directorate of Fisheries</span>
						</strong>
					</div>
				</Col>
				<Col
					sm={12}
					md={3}
					className="d-flex justify-content-md-end align-items-center"
				>
					<div className="d-none d-md-block fst-italic text-nowrap text-end">
						<strong>
							<span>FISH FARMER PORTAL</span>
							<br />
							<span>MEGHALAYA</span>
						</strong>
					</div>
					{/* Department Logo */}
					<a href="/">
						<img
							src={logo}
							width={180}
							alt="Department Logo"
							title="Fisheries Dept."
							className="mt-2"
						/>
					</a>
					<div className="d-sm-block d-md-none fst-italic">
						<strong>
							<span>FISH FARMER PORTAL</span>
							<br />
							<span>MEGHALAYA</span>
						</strong>
					</div>
				</Col>
			</Row>
		</div>
	</>
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
// #endregion

export default Header;