import React from 'react'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../helper/axios_authenticated_urls';
import axiosPublic from '../../helper/axios';
import requests from '../../helper/request';
import ReLoginModal from '../commons/ReLoginModal';
import { ArrowBack } from '@mui/icons-material';

function SuccessStoriesInput() {

    const [show, setShowRelogin] = useState(false);
    const handleShowReloginModal = () => setShowRelogin(true);
    const location = useLocation()
    // const updateData = location.state.props
    const navigate = useNavigate();

    const [fileSelected, setfileSelected] = useState(null);
    const [districts, setdistricts] = useState([]);
    const [fileError, setfileError] = useState(false);
    const [fileErrorMsg, setfileErrorMsg] = useState("");
    const [imageUpload, setimageUpload] = useState(false)

    const [successStories, setsuccessStories] = useState(
        {
            title: location.state ? location.state.title : "",
            description: location.state ? location.state.desc : "",
            districtCode: location.state ? location.state.districtCode : ""
        }
    );

    const [error, seterror] = useState(false);

    // This the method to get the district
    useEffect(() => {

        let getDistrict = async () => {
            let district = await axiosPublic.get(requests.fetchDistrict);
            setdistricts(district.data.data);
            //console.log(location.state.props)
            // console.log(location.state)
            // console.log("From")

        }

        getDistrict();
    }, []);

    const handleFieldChange = (event, field) => {

        seterror(false);
        setfileError(false);
        const data = event.target.value;
        // console.log(data)

        setsuccessStories({
            ...successStories,
            [field]: data
        })
    }

    const handleCreate = (event) => {

        // console.log(successStories)
        // console.log(fileSelected)

        // Create an object of formData
        const formData = new FormData();
        // Update the formData object
        formData.append(
            "file",
            fileSelected
        );
        formData.append(
            "successStories",
            JSON.stringify(successStories)
        )

        // Request made to the backend api
        // Send formData object

        if (successStories.title === "" || successStories.description === "" || successStories.districtCode === "") {
            seterror(true);
            return;
        }

        axios.post(requests.fetchSuccessStories, formData).
            then((response) => {
                if (response.data.status === 200) {
                    navigate("/admin/successStories")
                    // console.log(response)
                }
                else if (response.data.status === 401 || response.data.status === 403) {
                    handleShowReloginModal();
                }
            })
    }

    const handleUpdate = (event) => {
        debugger;
        seterror(false);
        setfileError(false);
        event.preventDefault();
        // console.log(location.state)
        // console.log(successStories)

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        if (fileSelected != null) {
            formData.append(
                "file",
                fileSelected
            );
        }

        successStories.id = location.state.id;

        formData.append(
            "successStories",
            JSON.stringify(successStories)
        )

        // Request made to the backend api
        // Send formData object

        if (successStories.title === "" || successStories.description === "" || successStories.districtCode === "") {
            seterror(true);
            return;
        }
        // console.log(formData)

        axios.patch(requests.fetchSuccessStories, formData).
            then((response) => {
                if (response.data.status === 200) {
                    navigate("/admin/successStories")
                    // console.log(response)
                }
                else if (response.data.status === 401 || response.data.status === 403) {
                    handleShowReloginModal();
                }
            })

    }

    const handleFileSelect = (event) => {
        // debugger;
        seterror(false);
        setfileError(false);

        event.preventDefault();
        // console.log(event.target.files[0].size)

        if (event.target.files[0].size / 10000000 >= 2) {
            setfileError(true)
            setfileErrorMsg("File size should be less than 2 M.B.")
            return;
        }

        if (event.target.files[0].type === "image/png" || event.target.files[0].type === "image/jpeg") {

            // console.log(event.target.files[0].type)
            setfileSelected(event.target.files[0]);
        }
        else {

            setfileError(true)
            setfileErrorMsg("File extension should be either png/jpeg/jpg")
            return;
        }
        // console.log(event.target.files[0].rawFile.type)
    }

    const handleImageEditButton = () => {
        // console.log("first")
        if (imageUpload) {
            setimageUpload(false)
        }

        else {
            setimageUpload(true)
        }

    }

    return (
        <>
            <div className='container'>
                {error ? <div class="alert alert-danger" role="alert">
                    Please Check all the fields
                </div> : null}
                <div className='card-header text-center text-uppercase fw-bold mt-2 p-2'>
                    {location.state ? "Upate the Success Stories" : "Add New Success Story"}
                </div>
                <div className='m-2'>
                    <ArrowBack onClick={() => navigate("/admin/successStories")}></ArrowBack>
                    <span className='btn btn-sm mx-2' onClick={() => navigate("/admin/successStories")}>Back</span>
                </div>
                <div className='card card-border-1 p-3 mb-5'>
                    <form>
                        <div className="mb-3">
                            <label for="title" className="form-label fw-bold" >Tile</label>
                            <input type="text" className="form-control" id="title" placeholder="Add The Title Here "
                                value={successStories.title}
                                onChange={(event) => handleFieldChange(event, 'title')} />
                        </div>
                        <div className="mb-3">
                            <label for="description" className="form-label fw-bold">Description</label>
                            <textarea className="form-control" id="description" rows="3"
                                value={successStories.description}
                                onChange={(event) => handleFieldChange(event, 'description')}
                            ></textarea>
                        </div>

                        <label for="districtCode" className='form-label fw-bold'> Select District </label>

                        <select class="form-select" aria-label="Default select example" placeholder='Select District'
                            id='districtCode'
                            value={successStories.districtCode}
                            onChange={(event) => handleFieldChange(event, 'districtCode')}>

                            <option value="" disabled selected >Select District</option>

                            {districts.map((districtIndividual) => {

                                return <option value={districtIndividual.districtCode}>{districtIndividual.districtName}</option>
                            })}

                        </select>
                        {error && successStories.districtCode === "" ? <small className='text-danger'>Please select a district</small> : null}

                        {location.state ? imageUpload ? <div className="my-3">
                            <label for="formFile" class="form-label fw-bold">Upload the image</label>
                            <input className="form-control" type="file" id="formFile" onChange={(event) => handleFileSelect(event)} />
                            {fileError ? <div class="alert alert-danger" role="alert">
                                {fileErrorMsg}
                            </div> : null}
                        </div> : null : <div className="my-3">
                            <label for="formFile" class="form-label fw-bold">Upload the image</label>
                            <input className="form-control" type="file" id="formFile" onChange={(event) => handleFileSelect(event)} />
                            {fileError ? <div class="alert alert-danger" role="alert">
                                {fileErrorMsg}
                            </div> : null}
                        </div>}
                        {/* {imageUpload ? <div className="my-3">
                                <label for="formFile" class="form-label">Upload The image</label>
                                <input className="form-control" type="file" id="formFile" onChange={(event) => handleFileSelect(event)} />
                                {fileError ? <div class="alert alert-danger" role="alert">
                                    {fileErrorMsg}
                                </div> : null}
                            </div> : null} */}
                        {location.state ?
                            <>
                                {/* <button className='btn btn-primary' onClick={(event) => handleUpdate(event)}>Update</button> */}
                                {/* <button className='btn btn-primary btn-sm mx-3 my-2' onClick={(() => handleImageEditButton())}>{imageUpload ? "Close Upload Image" : "Edit Image"}</button> */}
                            </> :
                            <button className='btn btn-primary btn-sm' onClick={(event) => handleCreate(event)}>Create</button>}

                    </form>
                </div>
                <ReLoginModal showModal={show}></ReLoginModal>
            </div >
        </>
    )
}

export default SuccessStoriesInput