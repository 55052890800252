
// For the process of validation we are going to use this Validation.js file and export all the methods
export const validateEmail = (email) => {
    var pattern = /[a-zA-Z0-9_.+-]+(@|\[at\])[a-zA-Z0-9-]+(\.|(\[dot\]))[a-zA-Z0-9-.]+/;
    return pattern.test(email);

}

export const validatePassword = (password) => {
    var pattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=.*[a-zA-Z@#$%^&+=]).{8,}/;

    return pattern.test(password);

}

export const validateUsername = (username) => {
    var pattern = /^[a-z][a-zA-Z0-9_]+$/;

    return pattern.test(username);
}