import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { Error } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { doLogout } from '../../auth/auth';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const ReLoginModal = ({ showModal, handleClose }) => {

    const navigate = useNavigate();

    return <div className='text-center'>

        <Modal show={showModal} onHide={handleClose} centered backdrop="static">
            <Modal.Header >
                <Modal.Title >Please Login your Session had expired</Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <Error fontSize='large' className='text-center danger'></Error>

                {/* <input type="submit"  value="Login"></input> */}

            </Modal.Body>
            <Modal.Footer >
                {/* <Button variant="secondary" onClick={handleClose} className="loginbtn">
                            Close
                        </Button> */}
                <Button variant="primary" onClick={() => { doLogout(() => { navigate("/"); }) }} className="loginbtn">
                    Login
                </Button>
            </Modal.Footer>
        </Modal>
    </div>;
}

ReLoginModal.propTypes = propTypes;
ReLoginModal.defaultProps = defaultProps;
// #endregion

export default ReLoginModal;