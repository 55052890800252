import React from 'react'
import emergingopportunity1 from '../../../images/msam/emergingopportunities1.jpg'
import emergingopportunity2 from '../../../images/msam/emergingopportunities2.jpg'

function EmergingOpportunity() {
  return (

    <>
    <div className='container py-5'>
      <div className='card border-0'>
        <div className='card-header'>
          <h2 className="text-center"> Mini Mission - V: Emerging Opportunities in the Fisheries Sector</h2>
        </div>
        <div className='card-body'>

        <div className='row'>
          <div className='col-md-3'>
          <img src={emergingopportunity1} alt="" className='img-fluid' ></img>
          </div>
          <div className='col-md-9'>
            <p className='text-justify'>The vast natural fishery resources of Meghalaya indicate the huge potential for tapping resources for development of fisheries and aquaculture in the State. Diversification and tapping of these potential resources in the most innovative and sustainable way for development and income generation through the sector may be the answer for achieving maximum sustainable yield. The diverse geographical and agro-climatic conditions of the state call for a more need based approach and specific mode of action for development. In this Mini Mission the Department aims to bring into light the large untapped potential and latest trends in the Sector that would promote a more diverse approach in livelihood opportunities, employment and economic upliftment through the sector. This Mini Mission would address the recreational value of fisheries and aquaculture, potential culture systems of new species of food value, Ornamental Fisheries and development of Marketing Linkages through modern hygienic transportation facilities and any other innovation and emerging activity in the sector. </p>
          </div>
        
          <div className='col-md-9'>
          <p>The objectives of this Mini Mission are as follows: </p>
          <ol>
	  <li>To create a general awareness on the latest technology and culture systems in the Fisheries Sector in both Culture and Capture segments</li>
	  <li>Create entrepreneurship and livelihood opportunities through fisheries sector activities.</li>
	  <li>Promote non-conventional mode of employment and livelihood through fisheries sector through Aqua-tourism etc.</li>
	  </ol>
          
          </div>
          <div className='col-md-3'>
          <img src={emergingopportunity2} alt="" className='img-fluid' ></img>
          </div>
        </div>

        <p className=""><a className='text-reset text-decoration-underline' href="http://msam.nic.in/docs/msam2_0.pdf" rel='noreferrer' target="_blank">For more details download mission document:</a></p>
  
        </div>
      </div>

        
        

{/* <p ><img src={paddy1} alt="" width="360" height="270" align="right"></img><span className="style7">Through the Meghalaya State Aquaculture Mission 1.0 (MSAM) the state has brought in an additional water area of 1808.80 hectaresbetween2012-2017. The state through creation of individual ponds has uplifted the livelihoods of the rural people and provided employment to the local youth. The state still needs to develop more water area to meet the increasing demand of fish. Under MSAM 2.0 it is sought to assist a minimum water area starting from 0.05 Hectare to a maximum water area of 1.0 Hectare to encourage more participation of the beneficiaries under the Mission. Construction of new ponds will be implemented mostly in the individual lands, community lands (on lease) etc. Meghalaya has vast land areas under the control of the village communities. The people of the state exhibit strong community engagement in fish capture in the rivers. If this takes place in the culture, rather than capture from the rivers, it will protect the indigenous fish species from extinction. The state has many untapped resources from the reservoirs and lakes. The state also has gained momentum from the practice of paddy-cum-fish culture. The water used for agriculture is also utilized for fish culture.</span></p> */}
{/* 
<!--<p className="imgclassName_l"><img src="images/ipwgh.jpg" alt="area_expn2" width="360" height="203" align="left" /></p>--> */}
  {/* <p className="imgclass_l"><img src={paddy2} alt="area_expn2" width="200" height="100" align="left"></img> Production of fish in rice fields is almost as old as the practice of paddy cultivation itself. As water stagnates in the fields for paddy culture, fishes which naturally occur in the nearby tanks and pools enter the paddy fields and grow there along with paddy until harvest time. This type of fish production dates back from very early days, even though it was based on capture rather than culture. Rice and fish exist at some level together for food particularly in South East Asian countries and there are age old systems prevalent for their combined cultivation in India.</p> */}
 

  
			
</div>
    
    </>
   
  )
}

export default EmergingOpportunity