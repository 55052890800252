import React from 'react'

function StateProfile() {
  return (
    <div className='container'>
      <div className='card border-0 mt-3'>
        <div className='card-title'>
          <div className='text-center text-uppercase h4'> <b>STATE PROFILE</b></div>

          <div className='text-center text-uppercase h4'><b>DIRECTORATE OF FISHERIES, MEGHALAYA</b></div>
          <div className='card-body'>
            <table class="table table-striped table-responsive">
              <thead>
                <tr>
                  <th scope="col">Sl No </th>
                  <th scope="col">Particulars</th>
                  <th scope="col">Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td> Fish Requirement in the State </td>
                  <td> Approx 32,000 MT </td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td> Projected Population 2020 </td>
                  <td> 37 lakhs </td>
                </tr>
                <tr>
                  <td>3.</td>
                  <td> Fish eaters @ 85%  </td>
                  <td> 32 lakhs </td>
                </tr>
                <tr>
                  <td>4.</td>
                  <td> Per capita fish consumption in the State 2021-22 </td>
                  <td> 10.30 Kg/person/year </td>
                </tr>
                <tr>
                  <td>5.</td>
                  <td> Number of existing Ponds as on 31.3.2022  </td>
                  <td> 35609 nos </td>
                </tr>
                <tr>
                  <td></td>
                  
                  <td> Water area  </td>
                  <td> 4537.87 Ha </td>
                </tr>

                <tr>
                  <td>6.</td>
                  <td> Fish Production during 2021-22 </td>
                  <td> 18235.403 MT </td>
                </tr>
                <tr>
                  <td>7.</td>
                  <td> Fishseed Production during 2021-22 </td>
                  <td> 19.4419 millions  </td>
                </tr>
                <tr>
                  <td>8.</td>
                  <td> Arrival of Fish (Fresh Fish & Dry Fish) from outside the  State during 2021-22  </td>
                  <td> 16115.65 MT  </td>
                </tr>
              
                <tr>
                  <td>9.</td>
                  <td> State-wise fish demand and supply gap (Inland). </td>
                  <td> Approx 14,000 MT during 2021-22  </td>
                </tr>

                <tr>
                  <td>10.</td>
                  <td> State-wise fishseed demand and supply gap (Inland). </td>
                  <td> 56.05 millions fry (approx.)  </td>
                </tr>

              </tbody>

            </table>
          </div>
        </div>
      </div>


    </div>
  )
}

export default StateProfile