
import React from 'react'
import { useState, useEffect } from 'react'
import axios from '../../helper/axios';
import requests from '../../helper/request';
import SuccessStoriesAdminCard from '../../components/success_stories/SuccessStoriesAdminCard.js'
import { useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';

function SuccessStoryAdmin() {

  const navigate = useNavigate();

  const [successStories, setsuccessStories] = useState([]);
  const [showModal, setShow] = useState(false);
  const [data, setData] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (props) => { setShow(true); setData(props) };


  useEffect(() => {

    let getSuccessStories = async () => {
      let stories = await axios.get(requests.fetchSuccessStories);
      setsuccessStories(stories.data.data);
      //console.log(stories)

    }

    getSuccessStories();
  }, []);



  return (
    <div className='container'>

      <>
        <h3 className='text-center mt-3'> Success Stories </h3>
        <div className='btn btn-primary float-end btn-sm my-1' onClick={() => navigate("update")}> <Add></Add>Add New Story</div>
        <div className='row mt-5'>
          {successStories ? successStories.map((story) => {

            return <>
              <div className="col-sm-6 col-md-4 col-lg-4 mt-4">
                <SuccessStoriesAdminCard
                  title={story.title}
                  desc={story.description}
                  createdDate={new Date(story.createdTime).toLocaleDateString('en-IN')}
                  districtName={story.district.districtName}
                  districtCode={story.district.districtCode}
                  id={story.id}
                  successStories={setsuccessStories}
                  location={story.fileLocation}

                > </SuccessStoriesAdminCard> </div>
            </>


          }) : null}
        </div>

      </>

    </div>
  )
}

export default SuccessStoryAdmin