import React from 'react'
import { isLoggedIn } from "../auth/auth";
import { getUserRole } from '../services/user-details';
import {Navigate, Outlet } from 'react-router-dom'

function AdminRoutes() {
  return (
    <div>
      {isLoggedIn() && (getUserRole()==="ADMIN" || getUserRole()==="SUPER_ADMIN")?<Outlet></Outlet>:<Navigate to="/"></Navigate>}
    </div>
  )
}

export default AdminRoutes