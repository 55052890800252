import React from 'react'
import { useEffect } from 'react'
import axios from '../../helper/axios'
import { useState } from 'react'
import requests from '../../helper/request';
import ReLoginModal from '../commons/ReLoginModal';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';

function NotificationAdmin() {

  const [notificationDetails, setNotificationDetails] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {

    let getNotitfication = async () => {

      const notifcation = await axios.get(requests.fetchNotification);

      if (notifcation.status === 200) {
        // console.log(notifcation.data.data)
        setNotificationDetails(notifcation.data.data)
        // console.log(notificationDetails)
      }
      else if (notifcation.status == 401 || notifcation.status == 403) {
        // console.log("Logout")
      }
      //  console.log(notificationDetails)

    }

    getNotitfication()

  }, [])

  // const handleUpdate = (notifcation)=>{

  //   navigate("update",{state:notifcation})

  // }

  const handleCreateNotification = () => {

    navigate("update")
  }

  const handleDelete = (id) => {

    //  console.log(id)

    axios.delete(requests.fetchNotification + "/" + id)
      .then((response) => {
        if (response.data.status === 200) {
          // console.log("Deleted")
          axios.get(requests.fetchNotification)
            .then((response) => {
              // console.log(response.data.data);
              setNotificationDetails(response.data.data)

            })
        }

        else if (response.data.status === 401 || response.data.status === 403) {
          // handleShowReloginModal();
        }

        else {
          // console.log("Not Deleted")
        }
      })
    // console.log(level)

  }

  return (
    <div className='container'>
      <div className='text-uppercase text-center mt-2 bg-light p-3'>
        Notification
      </div>
      <button className='btn btn-primary float-end btn-sm my-1' onClick={() => handleCreateNotification()}><Add></Add> Add Notification</button>
      <table className="table table-bordered ">
        <thead className="thead-dark table-dark text-center">
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Type Of Notification</th>
            <th>Active</th>
            <th>Is New</th>
            <th>Created Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {notificationDetails ? notificationDetails.map((notifcation) => {
            return (

              <tr key={notifcation.id}>
                <td>{notifcation.title}</td>
                <td>{notifcation.description}</td>
                <td>{notifcation.typeOfNotification}</td>
                <td>{notifcation.isActive ? "True" : "False"}</td>
                <td>{notifcation.isNew ? "True" : "False"}</td>
                <td>{JSON.stringify(new Date(notifcation.createdDate))
                  .substring(1, 11).split('-').reverse().join('-')}</td>
                <td>
                  {/* <button className='btn btn-sm btn-primary my-1' onClick={() => handleUpdate(notifcation)}><Edit fontSize='small'></Edit>Update</button> <br></br> */}
                  <button className='btn btn-sm btn-danger my-1' onClick={() => handleDelete(notifcation.id)}><Delete fontSize='small'></Delete>Delete</button>
                </td>

              </tr>)
          }) : null}
        </tbody>
      </table>
    </div>
  )
}

export default NotificationAdmin