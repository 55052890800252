import React from 'react';
import '../district/css/table.css'
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useState } from 'react';
import { useEffect } from 'react';
import axiosProtected from '../../../helper/axios_authenticated_urls';
import requests from '../../../helper/request';

import { useNavigate, useOutletContext } from "react-router-dom";
import { Edit } from '@mui/icons-material';
import { getCurrentUser } from '../../../services/user-details';
import LoadingSpinner from '../../commons/LoadingSpinner.js'


// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

const CustomFarmerDataTable = (props) => {
  const navigate = useNavigate();

  const columns = [
    // {
    //   field: 'Edit',
    //   headerName: 'Select',
    //   width: 80,
    //   renderCell: (params) =>
    //     <button className='btn-outline-primary btn btn-sm text-sm'
    //       onClick={() => { navigate("/farmer", { state: { id: params.row.fishFarmerId } }) }}
    //     >
    //       <Edit style={{ fontSize: "15px", margin: "2px" }} className></Edit>
    //       Edit
    //     </button>
    //   ,
    //   disableClickEventBubbling: true,
    // },
    { field: 'fishFarmerId', headerName: 'Fish Farmer Id', width: 160 },
    { field: 'epicNumber', headerName: 'Epic Number', width: 110 },
    { field: 'name', headerName: 'Name', width: 250, editable: false, headerAlign: "center" },
    { field: 'gender', headerName: 'Gender', width: 90, editable: false },
    { field: 'age', headerName: 'Age', type: 'number', width: 50, editable: true, align: "center" },
    { field: 'dob', headerName: 'Date of Birth', width: 115, type: "date", valueGetter: ({ value }) => value && new Date(value), align: "center" },
    { field: 'farmerId', headerName: 'Farmer Id', width: 160, editable: false },
    { field: 'locality', headerName: 'Locality', width: 150, editable: false },
    { field: 'villageName', headerName: 'Village name', width: 150, editable: false },
    { field: 'blockName', headerName: 'Block Name', width: 150, editable: false },
    { field: 'farmerMotherOrHusbandName', headerName: 'Father/Mother/Husband Name', width: 300, editable: false },

  ];

  const [details, setdetails] = useState([]);
  const [showModal, setShow] = useOutletContext();
  const [isLoading, setisLoading] = useState(false)

  // This is the method ot get the details of the farmers for the table
  useEffect(() => {

    // console.log(props.districtCode);

    let getFarmersDistrictWise = async () => {
      setisLoading(true)
      // console.log(props.districtCode.len)
      if (props.districtCode.length !== 0) {

        await axiosProtected.get(requests.fetchFishFarmersByDistrict + props.districtCode).then(
          (res) => {
            //debugger;
            setisLoading(false)
            setdetails(res.data.data);
            // console.log("Data=",res.data.data);
            props.setShowDataTable(true)
            // console.log(details);
          }

        )
          .catch(error => {
            if (error.response.status === 401 || error.response.status === 500 || error.response.status === 403) {
              // console.log("object")
              setShow(true)
              setisLoading(false)

            }
          }


          )

      }
      else {
        return;
      }

    }

    getFarmersDistrictWise();
    // fetchData();

  }, [props.districtCode])

  return (

    /**
     * to check
     * loading spinner
    */
    <>
      {props.showDataTable ?
        <div className='my-5' style={{ height: "70vh" }}>

          {getCurrentUser().officeLocation != null ? <div className='h4 text-center'>District: {getCurrentUser().officeLocation} </div> :

            <div className='h4 text-center'>District: {props.districtName}</div>}

          <DataGrid
            getRowId={(row) => row.epicNumber}
            rows={details}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            editable={false}
            components={{ Toolbar: GridToolbar }}

            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },

            }} />

        </div> : <LoadingSpinner></LoadingSpinner>}
      {/* <div className='my-3' style={{ height: "65vh", width: '100%' }}>
            {
                props.districtName ? <div className='my-3 h4 text-center'> Selected District: {props.districtName}</div>:null
                // console.log(blockName)
            }
            {props.showDataTable ? 
            <DataGrid
                getRowId={(row) => row.epicNumber}
                rows={details}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}

                disableSelectionOnClick
                editable={false}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            /> : null}
        </div>  */}
    </>
  );
}

CustomFarmerDataTable.propTypes = propTypes;
CustomFarmerDataTable.defaultProps = defaultProps;
// #endregion

export default CustomFarmerDataTable;