import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import axios from "../../helper/axios"
import requests from "../../helper/request"
import { useEffect, useState } from "react"

import { DataGrid, GridToolbar } from "@mui/x-data-grid"

import { HelpOutline } from "@mui/icons-material"

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */
const DashBoardofVerifiedFarmers = () => {
  const [value, setvalue] = useState([])
  const [yearSelected, setyearSelected] = useState("")
  let monthWiseDataArray = []

  const year = 2023 // the year when it will be launched
  const years = Array.from(
    new Array(new Date().getFullYear() - year + 1),
    (val, index) => index + year
  )

  let months = [
    "Months",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "Sepetember",
    "October",
    "November",
    "December",
    "Total",
  ]
  let disricts = [
    "District",
    "EAST GARO HILLS",
    "EAST KHASI HILLS",
    "WEST JAINTIA HILLS",
    "RI BHOI",
    "SOUTH GARO HILLS",
    "WEST GARO HILLS",
    "WEST KHASI HILLS",
    "NORTH GARO HILLS",
    "EAST JAINTIA HILLS",
    "SOUTH WEST KHASI HILLS",
    "SOUTH WEST GARO HILLS",
    "EASTERN WEST KHASI HILLS",
    "Total",
  ]

  let monthWiseDataTableForFarmers = Array.from(
    Array(months.length),
    () => new Array(disricts.length)
  )

  for (let index = 0; index < months.length; index++) {
    monthWiseDataTableForFarmers[0][index] = months[index]
  }
  for (let index = 0; index < disricts.length; index++) {
    monthWiseDataTableForFarmers[index][0] = disricts[index]
  }

  for (let month = 1; month < months.length; month++) {
    for (let district = 1; district <= disricts.length - 1; district++) {
      monthWiseDataTableForFarmers[month][district] = 0
    }
  }

  useEffect(() => {
    if (yearSelected != "Year") {
      let monthWiseData = async () => {
        let data = await axios.get(
          requests.fetchMonthWiseData + "/" + yearSelected
        )

        let dataArray = data.data.data

        if (dataArray.length !== 0) {
          dataArray.forEach((element) => {
            monthWiseDataArray.push({
              month: element[0],
              countOfFarmers: element[1],
              district: disricts.indexOf(element[2]),
            })
          })
          //console.log(monthWiseDataArray)
          monthWiseDataArray.forEach((e) => {
            // console.log(e)
            // if (e.month === 0 && e.countOfFarmers) {
            //     for (let index = 1; index < 14; index++) {
            //         // console.log(monthWiseDataTableForFarmers[monthWiseDataArray.district][index])
            //         monthWiseDataTableForFarmers[e.district][index] = 0;
            //     }
            // }

            // else {

            for (let index = 1; index < 13; index++) {
              if (e.month === index) {
                monthWiseDataTableForFarmers[e.district][e.month] =
                  e.countOfFarmers

                //console.log(monthWiseDataTableForFarmers[e.district][e.month])
              }

              // else {

              //monthWiseDataTableForFarmers[e.district][index] = 0;
              //console.log(monthWiseDataTableForFarmers[e.district][e.month])
              // }
              // console.log(monthWiseDataTableForFarmers[e.district][index])
            }
            // }
            //console.log(monthWiseDataTableForFarmers);
            setvalue(monthWiseDataTableForFarmers)
            //console.log(value)
          })
        }

        var sum = 0
        for (let district = 1; district <= 12; district++) {
          for (let j = 1; j < 13; j++) {
            sum = sum + monthWiseDataTableForFarmers[district][j]
          }
          monthWiseDataTableForFarmers[district][13] = sum
          sum = 0
        }
        for (let months = 1; months <= 13; months++) {
          for (let j = 1; j < 13; j++) {
            sum = sum + monthWiseDataTableForFarmers[j][months]
          }
          monthWiseDataTableForFarmers[13][months] = sum
          sum = 0
        }
        setvalue(monthWiseDataTableForFarmers)
      }

      monthWiseData()
    }
  }, [yearSelected])

  return (
    <div>
      <h2 className="text-center mt-3"> Monthly Registration</h2>
      <div className="row">
        <div className="col-md-3 mx-auto">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="dropdown-basic-button">Select Year</label>
            </div>
            <div className="col-md-8">
              <select
                id="dropdown-basic-button"
                className="form-control"
                title="Year"
                value={yearSelected}
                onChange={(e) => {
                  setyearSelected(e.target.value)
                  // console.log(e.target.value);
                  // setshowing(true);
                }}
              >
                <option value="" disabled>
                  --Select Year--
                </option>
                {years.map((year, index) => {
                  return (
                    <option key={`year${index}`} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3  text-color-secondary"></div>
      {yearSelected ? (
        <div className="container-fluid">
          <div className="container table-responsive mt-4">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <td colSpan={14} style={{ fontWeight: "700" }}>
                    Number of Fish Farmer's Verified Month Wise for the{" "}
                    {yearSelected == "Year" ? null : "year " + yearSelected}
                  </td>
                </tr>
              </thead>
              <tbody>
                {value.map((row, month) => {
                  if (month == 0) {
                    return (
                      <tr key={month} className="table-dark">
                        {row.map((e, District) => {
                          // console.log(District + "keys");
                          return <td key={District}>{e}</td>
                        })}
                      </tr>
                    )
                  }
                  return (
                    <tr key={month}>
                      {row.map((e, District) => {
                        // console.log(District + "keys");
                        return <td key={District}>{e}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </div>
  )
}

DashBoardofVerifiedFarmers.propTypes = propTypes
DashBoardofVerifiedFarmers.defaultProps = defaultProps
// #endregion

export default DashBoardofVerifiedFarmers
