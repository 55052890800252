import React from 'react'
import { useState, useEffect } from 'react';
import axios from '../../helper/axios_authenticated_urls';
import axiosPublic from '../../helper/axios';
import requests from '../../helper/request';
import { Edit, LockOutlined } from '@mui/icons-material';

import UpdateUserModal from './UpdateUserModal';
import { Delete } from '@mui/icons-material';
import Tippy from '@tippyjs/react';
import DeactivateModal from './DeactivateModal';
import 'tippy.js/dist/tippy.css'; // optional
import ReLoginModal from '../commons/ReLoginModal';
import CustomTabs from '../commons/CustomTabs';
import ChangePassword from './ChangePassword';

function UsersDetailsPage() {

  const [userdetails, setuserdetails] = useState([]);
  const [show, setshow] = useState(false);
  const [user, setuser] = useState();
  const [districts, setdistricts] = useState([]);
  const [roles, setroles] = useState([]);
  const [pagination, setPagination] = useState({});
  const [pageNumber, setpageNumber] = useState(0);
  const [showDeactivateModal, setshowDeactivateModal] = useState(false)
  const [reloginModal, setReloginModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [update, setUpdate] = useState(false);

  const handleReloginShow = () => {
    setReloginModal(true);
  }

  //Handle Deactivate Modal

  const handleDeactivateModalShow = (user) => {
    setuser(user);
    setshowDeactivateModal(true);
  }

  const handleDeactivateModalClose = () => {
    setshowDeactivateModal(false);
  }

  const handleChangePasswordClose = () => {
    setShowChangePasswordModal(false);
  }

  const handleChangePassword = (userdetails) => {
    setuser(userdetails)
    setShowChangePasswordModal(true)
  }


  // Update Modal Show methods
  const handldeShow = (user) => {
    setuser(user);
    setshow(true);
    setUpdate(true)
  }
  const handleClose = () => {
    setshow(false);
    setuser(null)
  };

  // get all the Users api 
  useEffect(() => {

    const getUserDetails = async () => {
      // debugger;

      // if(showDeactivateModal)
      const details = await axios.get(requests.fetchAllUsers + "?page=" + pageNumber + "&size=10");
      // console.log(details.data.status )
      //  debugger;
      if (details.data.status === 401 || details.data.status === 403) {
        handleReloginShow()
        // console.log("Access Denied")
      }

      else {
        setuserdetails(details.data.data.users);
        setPagination(details.data.data._pagination);
        // console.log(pagination)


      }

    }

    getUserDetails();

  }, [pageNumber, showDeactivateModal])




  // Get all the districts api

  useEffect(() => {

    const getDistricts = async () => {

      const dis = await axiosPublic.get(requests.fetchDistrict);
      setdistricts(dis.data.data)
      // console.log(details);
      if (dis.data.status === 401 || dis.data.status === 403) {
        // console.log("Access Denied")
      }

      else {
        setdistricts(dis.data.data);
      }

    }

    getDistricts();

  }, [])

  // Get all the roles api 

  useEffect(() => {

    const getAllRoles = async () => {

      const role = await axios.get(requests.fetchAllRoles);
      setroles(role.data.data)
      // console.log(details);
      if (role.data.status === 401 || role.data.status === 403) {
        // debugger;
        // console.log("Access Denied")
        setReloginModal(true)
      }

      else {
        setroles(role.data.data);
      }

    }

    getAllRoles();

  }, [])

  const handlePageNumber = (event) => {
    setpageNumber(event.target.innerHTML - 1);

  }

  // Methods for the next page and previous Page

  const handleNextPage = () => {

    // console.log(pageNumber);
    setpageNumber(pageNumber + 1);
  }

  const handlePrevious = () => {
    if (pageNumber >= 0) {
      // console.log(pageNumber);
      setpageNumber(pageNumber - 1);
    }

  }

  var delay = (function () {

    var timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };

  })();


  const handleSearch = (event) => {

    let search = event.target.value;

    if (search !== "") {
      delay(async function () {

        const details = await axios.get(requests.fetchAllUsers + "?page=" + pageNumber + "&size=10&search=" + search)

          .then((response) => {

            if (response.data.status === 200) {

              setuserdetails(response.data.data.users);
              setPagination(response.data.data._pagination);

            }

            else if (response.data.status === 401 || response.data.status === 403) {

              setReloginModal(true)
            }
          });
        // console.log((details));
      }, 1000);
    }

    else {
      return axios.get(requests.fetchAllUsers + "?page=" + pageNumber + "&size=10" + search)

        .then((response) => {

          if (response.data.status === 200) {

            setuserdetails(response.data.data.users);
            setPagination(response.data.data._pagination);

          }
          else if (response.data.status === 401 || response.data.status === 403) {
            handleReloginShow();
          }
        });
    }


    // end delay

  }

  const addUser = () => {
    // console.log("first")
    setshow(true)
    setUpdate(false);
  }





  return (
    <>

      <div className='container'>

        <div className='card border bg-light'>
          <div className='mt-3 text-center text-uppercase p-2 fw-bold'>Users Details</div>
        </div>

        {pageNumber == 0 ? <form class="d-flex float-start my-2">
          <input class="form-control me-2" id="search" name="search" type="search" onChange={(event) => handleSearch(event)} placeholder="Search" aria-label="Search" />

        </form> : null}
        {pageNumber == 0 ? <button className='btn btn-primary btn-sm float-end mt-2' onClick={() => addUser()}>Add</button> : null}

        <table class="table">
          <thead>
            <tr>
              <th className='text-center' scope="col">Name</th>
              <th className='text-center' scope="col">User Name</th>
              <th className='text-center' scope="col">Office Location</th>
              <th className='text-center' scope='col'>User Roles</th>
              <th className='text-center' scope="col">Active/Detactive</th>
              <th className='text-center' scope='col'>Actions</th>

            </tr>
          </thead>
          <tbody>

            {
              userdetails.map((userDetail) => {

                return (
                  <tr id={userDetail.id}>

                    <td >{userDetail.name}</td>
                    <td >{userDetail.userName}</td>
                    <td >{userDetail.officeLocation}</td>
                    <td>{userDetail.roles.map((role) => {
                      return (
                        <>
                          {role.name}
                          {/* <Edit fontSize='small' onClick={() => handleEditRole(userDetail)}></Edit> */}
                          <br></br>
                        </>)
                    })}</td>
                    <td>
                      <div class="form-switch">
                        <label className="form-check-label" for="flexSwitchCheckDefault">{userDetail.isActive ? "Active" : "Deactivated"}</label>
                        <input className="form-check-input" style={{ marginLeft: "10px" }} onClick={() => handleDeactivateModalShow(userDetail)} checked={userDetail.isActive ? true : false} type="checkbox" role="switch" id="flexSwitchCheckDefault" />

                      </div></td>

                    <td>
                      <Tippy content="Edit User">
                        <Edit className='m-2 text-secondary' onClick={() => handldeShow(userDetail)}><button className='btn btn-sm ' >Update</button></Edit></Tippy>
                      {/* <Delete className='m-2 text-danger' onClick={() => (userDetail)}><button className='btn btn-sm ' >Delete</button></Delete> */}
                      <LockOutlined onClick={() => handleChangePassword(userDetail)}></LockOutlined>
                    </td>


                  </tr>
                )
              })
            }

          </tbody>
        </table>

        {/* Pagination Related Code Starts Here */}

        <div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">

                <button className="page-link text-dark" href="#" aria-label="Previous" onClick={handlePrevious} disabled={pagination.isFirst ? true : false}>
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </button>
              </li>
              <li className="page-item" ><button className={`page-link ${pageNumber === 0 ? " bg-primary text-white" : "text-dark"}`} onClick={(event) => handlePageNumber(event)}>1</button></li>
              <li className="page-item"><button className={`page-link  ${pageNumber === 1 ? " bg-primary text-white" : "text-dark"}`} onClick={(event) => handlePageNumber(event)}>2</button></li>
              <li className="page-item"><button className={`page-link  ${pageNumber >= 2 ? " bg-primary text-white" : "text-dark"}`} onClick={(event) => handlePageNumber(event)}>{pageNumber <= 3 ? 3 : pageNumber}</button></li>
              <li className="page-item">

                {/* Check disabled and isLast*/}
                <button className="page-link text-dark" href="#" aria-label="Next" onClick={handleNextPage} disabled={pagination.isLast ? true : false}>

                  <span className="sr-only">Next</span>
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
        {/* To update */}

        <UpdateUserModal show={show} handleClose={handleClose} user={user} districts={districts} role={roles} setuser={setuser} update={update}></UpdateUserModal>
        <DeactivateModal showDeactivateModal={showDeactivateModal} handleDeactivateModalClose={handleDeactivateModalClose} user={user}></DeactivateModal>
        <ReLoginModal showModal={reloginModal}></ReLoginModal>
        <ChangePassword showChangePasswordModal={showChangePasswordModal} handleChangePasswordClose={handleChangePasswordClose} user={user}></ChangePassword>


      </div>
    </>

  )
}

export default UsersDetailsPage