import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import requests from '../../../helper/request';

import { useEffect, useState } from 'react';
import axios from '../../../helper/axios';
import axiosProtected from '../../../helper/axios_authenticated_urls'
import { useOutletContext } from "react-router-dom";
import AdminBlockSelectionComponent from './AdminBlockSelectionComponent';
import BlockSelectionComponent from './BlockSelectionComponent';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};



/**
 * 
 */
const BlockDashBoard = () => {
    const [showModal, setShow, districtCode, userRole] = useOutletContext();


    // console.log(districtCode)

    return (
        <>
            {districtCode ?
                <BlockSelectionComponent districtCode={districtCode} setShow={setShow}></BlockSelectionComponent>
                : <AdminBlockSelectionComponent setShow={setShow}></AdminBlockSelectionComponent>}




        </>
    );
}

BlockDashBoard.propTypes = propTypes;
BlockDashBoard.defaultProps = defaultProps;
// #endregion

export default BlockDashBoard;