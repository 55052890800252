import React from 'react'
import CustomTabs from '../../commons/CustomTabs'

function NotificationSection() {
  return (
    <div className='container'>
      <CustomTabs></CustomTabs>
      </div>
  )
}

export default NotificationSection