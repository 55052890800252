import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigate, Navigate } from 'react-router-dom'
import VillageDashboardForPond from './VillageDashboardForPond';


// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const BlockWiseDataCard = (props) => {

    const navigate = useNavigate();
    // props.setTotalCount(props.totalCount + props.countOfFarmers)

    const navigateToVillageDashBoard = () => {
        //  console.log(props.setShow);
        // <Navigate to="/dashboard/pondetails/villageWise" element={<VillageDashboardForPond></VillageDashboardForPond>}></Navigate>
        navigate("../ponddetails/villagewise", { state: { id: props.blockCode, name: props.blockName, show: props.setShow() } })
    }

    return <>
        <div className="bg-light py-4 b-dbcard shadow-lg my-3 text-center">
            {/* { <props.Icon fontSize='large' ></props.Icon>} */}

            <h4 className='text-center text-uppercase'> {props.blockName}</h4>
            <div className="text-left ">
                <p className="px-5"> <span className="float-right h1">{props.countOfFarmers}</span></p>

            </div>
            <button className='btn btn-success btn-sm text-center' onClick={navigateToVillageDashBoard}> View More</button>

        </div>
    </>
}

BlockWiseDataCard.propTypes = propTypes;
BlockWiseDataCard.defaultProps = defaultProps;
// #endregion

export default BlockWiseDataCard;