import React from 'react'
import { useNavigate } from 'react-router-dom'

function Scheme() {

     const navigate = useNavigate();
    return (
        <div className='container'>
            <table class="table table-hover table-responsive">
                <thead>
                    <tr>
                        <th scope="col">Serial no.</th>
                        <th scope="col">Name</th>
                        <th scope="col">Action</th>
                       
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">1</th>
                        <td>Mini Mission I - Area and Productivity Expansion</td>
                        <td> <button className='btn btn-primary btn-sm' onClick={ ()=>{navigate("/areaproductivity")}}> View More</button></td>
                    </tr>
                    <tr>
                        <th scope="row">2</th>
                        <td>Mini Mission II - Critical Infrastructure Development</td>
                        <td> <button className='btn btn-primary btn-sm' onClick={ ()=>{navigate("/criticalInfra")}}> View More</button></td>
                        
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <td>Mini Mission III - Establishing sanctuaries for conserving indigenous and endemic species</td>
                        <td> <button className='btn btn-primary btn-sm' onClick={ ()=>{navigate("/establishSanctuaries")}}> View More</button></td>
                        
                    </tr>
                    <tr>
                        <th scope="row">4</th>
                        <td>Mini Mission IV - Capacity Building and Human Resource Development</td>
                        <td> <button className='btn btn-primary btn-sm' onClick={ ()=>{navigate("/capacitybuilding")}}> View More</button></td>
                        
                    </tr>
                    <tr>
                        <th scope="row">5</th>
                        <td>Mini Mission V - Emerging opportunities in the Fisheries Sector</td>
                        <td> <button className='btn btn-primary btn-sm' onClick={ ()=>{navigate("/emergingopportunity")}}> View More</button></td>
                        
                    </tr>
                
                </tbody>
            </table>
        </div>
    )
}

export default Scheme