import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import notfound from '../../images/404image.gif'
// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const Errornotfound = () => {
    return <div className='text-center '>
            {/* <img src={notfound}  alt="404" ></img> */}
            <div className='text-center'> 404 Page not Found</div>
    </div>;
}

Errornotfound.propTypes = propTypes;
Errornotfound.defaultProps = defaultProps;
// #endregion

export default Errornotfound;