import React from 'react'

import { Route, Routes, useNavigate } from 'react-router-dom';
import HomeDashBoard from '../components/dashboard/homedashborad/HomeDashBoard';

import Errornotfound from '../components/error/Errornotfound';
import FarmerDetails from '../components/dashboard/district/FarmerDetails';
import DashBoardofVerifiedFarmers from '../components/dashboard_verified_farmers/DashBoardofVerifiedFarmers';
import BlockDashBoard from '../components/dashboard/block/BlockDashBoard';
import PondDetails from '../components/dashboard/ponddetails/PondDetails';
import VillageDashboardForPond from '../components/dashboard/ponddetails/VillageDashboardForPond';
import FishFarmerDetailsWithPond from '../components/dashboard/ponddetails/FishFarmerDetailsWithPond';
import SingleFarmerDetail from '../components/forms/SingleFarmerDetail';
import PrivateRoutes from '../helper/PrivateRoutes';
import AuditTrail from '../components/dashboard/audit/AuditTrail';
// import Contact from '../components/contact/Contact';

import Schemes from '../components/schemes/Scheme';
import AboutFFDA from '../components/about/AboutFFDA';
import AreaAndProductivity from '../components/schemes/msam2.0/AreaAndProductivity';
import CriticalInfraStructure from '../components/schemes/msam2.0/CriticalInfraStructure';
import EstablishingSanctuaries from '../components/schemes/msam2.0/EstablishingSanctuaries';
import CapacityBuilding from '../components/schemes/msam2.0/CapacityBuilding';
import EmergingOpportunity from '../components/schemes/msam2.0/EmergingOpportunity';
import CentralScheme from '../components/schemes/CentralScheme';
import ContactUsInput from '../components/forms/ContactUsInput';
import AdminRoutes from '../helper/AdminRoutes';
import SuperAdminRoutes from '../helper/SuperAdminRoutes';
import ContactUsAdmin from '../components/contact/ContactUsAdmin';
import Home from '../components/landingpage/Home';
import Dashboard from '../components/dashboard/Dashboard';
import StateProfile from '../components/fisheries_senario/StateProfile';
import FisheriesSenario from '../components/fisheries_senario/FisheriesSenario';
import SuccessStoriesInput from '../components/forms/SuccessStoriesInput';
import SuccessStoryAdmin from '../components/success_stories/SuccessStoryAdmin';
import PrivacyPolicy from '../components/terms_and_condtion_privacy_policy/PrivacyPolicy';
import TermsAndCondition from '../components/terms_and_condtion_privacy_policy/TermsAndCondition';
import UsersDetailsPage from '../components/user_management/UsersDetailsPage';
import { isLoggedIn } from '../auth/auth';
import { useEffect } from 'react';
import axiosProtected from '../helper/axios_authenticated_urls';
import requests from './request';
import { verifyJwtToken } from './jwtverification';
import NotificationAdmin from '../components/notification/NotificationAdmin';
import NotificationUpdateForm from '../components/forms/NotificationUpdateForm';
import BoatLoaderLazy from '../components/commons/BoatLoaderLazy';

// For Lazy Loading 
const LazyAbout = React.lazy(() => import('../components/about/About'))
const LazyContactUs = React.lazy(() => import('../components/contact/Contact'))
const LazyServices = React.lazy(() => import('../components/services_component/Services'))
const LazyDashBoardPublic = React.lazy(() => import('../components/public_dashboard/PublicDashboard'))
const LazySuccessStories = React.lazy(() => import('../components/success_stories/SuccessStories'))
const LazyFormSection  = React.lazy(()=>import('../components/form_section/FormSection'))



function AppRoutes() {

  const navigate = useNavigate();

  useEffect(() => {

    verifyJwtToken(navigate);
  }, [])

  // const MINUTE_MS = 1200000; // 20 minute
  // const MINUTE_MS = 1440000; // 24 minute
  const MINUTE_MS = 1440000; // 24 minute

  useEffect(() => {

    const interval = setInterval(() => {
      const regenrateToken = async () => {
        if (isLoggedIn()) {
          let tokenData = await axiosProtected.get(requests.fetchRegenerateToken);
          let data = tokenData.data.data;
          // console.log(data); 
          localStorage.removeItem("data");
          localStorage.setItem("data", JSON.stringify(data));
        }


      }

      regenrateToken();
    }, MINUTE_MS);

    return () => clearInterval(interval);


    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])
  // if(isLoggedIn()? getDetail():null)

  return (
    <>

      <Routes>

        <Route exact path="/" element={<Home></Home>} />
        <Route exact path='/home' element={<Home></Home>} />
        <Route exact path="/reports" element={<FarmerDetails></FarmerDetails>} />
        <Route exact path="/about"
          element={<React.Suspense
            fallback={<BoatLoaderLazy></BoatLoaderLazy>}>
            <LazyAbout></LazyAbout></React.Suspense>} />
        <Route exact path="/services" element={<React.Suspense
          fallback={<BoatLoaderLazy></BoatLoaderLazy>}>
          <LazyServices></LazyServices> </React.Suspense>} />
        <Route exact path="/contact"
          element={<React.Suspense
            fallback={<BoatLoaderLazy></BoatLoaderLazy>}>
            <LazyContactUs></LazyContactUs></React.Suspense>} ></Route>
        <Route exact path='/verifiedFarmers' element={<DashBoardofVerifiedFarmers></DashBoardofVerifiedFarmers>} />
        <Route exact path='/formsSection' element={<React.Suspense
            fallback={<BoatLoaderLazy></BoatLoaderLazy>}>
            <LazyFormSection></LazyFormSection></React.Suspense>} />
        < Route exact path='/schemesMSAM' element={<Schemes></Schemes>} ></Route>
        < Route exact path='/centralScheme' element={<CentralScheme></CentralScheme>} ></Route>
        < Route exact path='/areaproductivity' element={<AreaAndProductivity></AreaAndProductivity>}></Route>
        < Route exact path='/criticalInfra' element={<CriticalInfraStructure></CriticalInfraStructure>}></Route>
        < Route exact path='/privacyPolicy' element={<PrivacyPolicy></PrivacyPolicy>}></Route>
        < Route exact path='/termsandcondition' element={<TermsAndCondition></TermsAndCondition>}></Route>
        < Route exact path='/establishSanctuaries' element={<EstablishingSanctuaries></EstablishingSanctuaries>}></Route>
        < Route exact path='/capacitybuilding' element={<CapacityBuilding></CapacityBuilding>}></Route>
        < Route exact path='/emergingopportunity' element={<EmergingOpportunity></EmergingOpportunity>}></Route>
        <Route exact path='/stateProfile' element={<StateProfile></StateProfile>}></Route>
        <Route exact path='/fisheriesSenario' element={<FisheriesSenario></FisheriesSenario>}></Route>
        <Route exact path='/aboutffda' element={<AboutFFDA></AboutFFDA>} />
        <Route exact path='/successStories' element={<React.Suspense
          fallback={<BoatLoaderLazy></BoatLoaderLazy>}>
          <LazySuccessStories></LazySuccessStories>
        </React.Suspense>}>
        </Route>
        <Route exact path="/public/dashboard" element={<React.Suspense
          fallback={<BoatLoaderLazy></BoatLoaderLazy>}>
          <LazyDashBoardPublic></LazyDashBoardPublic></React.Suspense>}></Route>
        

        {/* Routes only available on login */}
        <Route element={<PrivateRoutes></PrivateRoutes>}>
          <Route exact path='/farmer' element={<SingleFarmerDetail></SingleFarmerDetail>}></Route>

          {/* Routes inside the Dashboard */}
          <Route exact path="/dashboard" element={<Dashboard></Dashboard>}>
            <Route path="districtdetails" element={<FarmerDetails></FarmerDetails>}></Route>
            <Route path="home" element={<HomeDashBoard></HomeDashBoard>}></Route>
            <Route path='blockdetails' element={<BlockDashBoard></BlockDashBoard>}></Route>
            <Route path='auditTrail' element={<AuditTrail></AuditTrail>}></Route>
            <Route path='ponddetails/blockwise' element={<PondDetails></PondDetails>}></Route>
            <Route path='ponddetails/villagewise' element={<VillageDashboardForPond></VillageDashboardForPond>}></Route>
            <Route path='ponddetails/fishfarmerdetailswithponds' element={<FishFarmerDetailsWithPond></FishFarmerDetailsWithPond>}></Route>
          </Route>

          {/* Paths or pages access by only Admin and Super Admin */}
          <Route element={<AdminRoutes></AdminRoutes>}>
            <Route exact path='admin/contact/' element={<ContactUsAdmin></ContactUsAdmin>}></Route>
            <Route exact path='admin/contact/update' element={<ContactUsInput></ContactUsInput>}></Route>
            <Route exact path='/admin/userdetails' element={<UsersDetailsPage></UsersDetailsPage>}></Route>
            <Route exact path='/admin/successStories' element={<SuccessStoryAdmin></SuccessStoryAdmin>}> </Route>
            <Route exact path='/admin/successStories/update' element={<SuccessStoriesInput></SuccessStoriesInput>} />
            
            <Route exact path='/admin/notification' element={<NotificationAdmin></NotificationAdmin>}></Route>
            <Route exact path='/admin/notification/update' element={<NotificationUpdateForm></NotificationUpdateForm>}></Route>
            {/* <Route exact path='/admin/successStories/update' element={<SuccessStoriesInput></SuccessStoriesInput>}/> */}
          </Route>

          {/* Paths or Pages accessd by only the Super Admin */}
          <Route element={<SuperAdminRoutes></SuperAdminRoutes>}>

          </Route>
        </Route>

        {/* Paths for the eroor pages  */}
        <Route path='*' element={<Errornotfound />} />
      </Routes>
    </>

  )
}

export default AppRoutes