import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import axios from '../../../helper/axios';
import requests from '../../../helper/request';
import DistrictHomeDashBoard from './DistrictHomeDashBoard';
import { Backdrop } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const AdminDistrictSelectionComponent = (props) => {



  const [showDistrictDashBoard, setShowDistrictDashBoard] = useState(false);
  const [district, setdistrict] = useState([]);
  const [districtSelected, setdistrictSelected] = useState([]);
  const [districtName, setdistrictName] = useState("");


  // This the method to get the district
  // useEffect(() => {

  //   let getDistrict = async () => {
  //     let districts = await axios.get(requests.fetchDistrict);
  //     setdistrict(districts.data.data);

  //   }

  //   getDistrict();
  // }, []);

  const handleNavigateToDistrict = (district) => {
    setdistrictSelected(district.districtCode);
    setdistrictName(district.districtName);
    // console.log(district)
    // console.log("first")
    setShowDistrictDashBoard(true);



  }


  const [districtFarmerCount, setdistrictFarmerCount] = useState([]);

  useEffect(() => {

    let getDistrictWise = async () => {

      let districtCount = await axios.get(requests.fetchFishFarmerCountByDistrict);
      //console.log(districtCount.data.data);
      setdistrictFarmerCount(districtCount.data.data)
    }

    getDistrictWise();

  }, []);


  return (
    <>

      {

        showDistrictDashBoard ? null :

          <div>


            <div className='row'>
              {districtFarmerCount ? districtFarmerCount.map((district, key) => {

                return (

                  <div className='col-4'>
                    <div className="bg-light py-4 b-dbcard shadow-lg my-3 text-center">
                      {/* { <props.Icon fontSize='large' ></props.Icon>} */}

                      <h4 className='text-center text-uppercase'> {district.districtName}</h4>
                      <div className="text-left ">
                        <p className="px-5"> <span className="float-right h1">{district.districtCountOfFarmers}</span></p>

                      </div>
                      <button className='btn btn-success btn-sm text-center' onClick={() => handleNavigateToDistrict(district)}> View More</button>

                    </div>
                  </div>

                )
              }) : null}
            </div>
          </div>
      }
      {

        showDistrictDashBoard ? <> <button className='btn-secondary p-1 btn-small' onClick={() => { setShowDistrictDashBoard(false) }}><ArrowBack >  </ArrowBack>Back</button>
          <DistrictHomeDashBoard districtCode={districtSelected} setShow={props.setShow} districtName={districtName}></DistrictHomeDashBoard> </> : null
      }
    </>
  )


}

AdminDistrictSelectionComponent.propTypes = propTypes;
AdminDistrictSelectionComponent.defaultProps = defaultProps;
// #endregion

export default AdminDistrictSelectionComponent;