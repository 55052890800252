import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import axiosProtected from '../../../helper/axios_authenticated_urls';
import requests from '../../../helper/request';
import BlockWiseDataCard from './BlockWiseDataCard';
import { getOfficeLocation } from '../../../services/user-details';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const PondDetailsDistrictDashBoard = (props) => {

    const [blockWiseData, setblockWiseData] = useState([])
    const [totalCount, settotalCount] = useState(0);

    useEffect(() => {

        if (props.districtCode) {

            let getBlockWiseFarmer = async () => {
                await axiosProtected.get(requests.fetchAllPondCountOfEachBlockInDistrict + "/" + props.districtCode).then(
                    (res) => {

                        setblockWiseData(res.data.data.blockDTOList);
                        settotalCount(res.data.data.totalCount)
                        //console.log(res);
                    }
                )
                    .catch(error => {
                        if (error.response.status === 401 || error.response.status===500 || error.response.status ===403) {
                            // console.log("object")
                            props.setShow(true)
                        }
                    }

                    )

            }
            getBlockWiseFarmer();
        }



    }, [props.districtCode]);

    return <div >
        <div className='my-2'>
            <h2 className='text-center mt-3'>Projects Registered in {props.districtName ? props.districtName : getOfficeLocation()}</h2>
            <div className='card my-3 bg-light shadow p-3 '>
            <div className='h4 text-center'> Total Registered: {totalCount}</div>
            </div>
      
        </div>
        
        <div className='row'>

            {blockWiseData.map((blockData, key) => {

                return (
                    <div className='col-4'>
                        <BlockWiseDataCard blockCode={blockData.blockCode}
                            blockName={blockData.blockName}
                            countOfFarmers={blockData.countOfFarmers}
                            setShow={props.setShow}>
                                
                            </BlockWiseDataCard>
                    </div>
                )
            })}

        </div>
    </div>;
}

PondDetailsDistrictDashBoard.propTypes = propTypes;
PondDetailsDistrictDashBoard.defaultProps = defaultProps;
// #endregion

export default PondDetailsDistrictDashBoard;