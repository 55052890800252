import React from 'react'
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from '../../helper/axios_authenticated_urls';
import axiosPublic from '../../helper/axios';
import requests from '../../helper/request';
import ReLoginModal from '../commons/ReLoginModal';
import { useState } from 'react';

function SuccessStoriesAdminCard(props) {

    const [show, setShowRelogin] = useState(false);
    const handleShowReloginModal = () => setShowRelogin(true);

    const navigate = useNavigate();

    const handleEdit = (e, props) => {

        //This is deserializing the data from props 
        let data = {
            id: props.id,
            title: props.title,
            desc: props.desc,
            districtCode: props.districtCode,
            districtName: props.districtName,
        }
        e.preventDefault();
        navigate("update", { state: data })

    }

    const handleDelete = (e, props) => {
        e.preventDefault();

        axios.delete(requests.fetchSuccessStories + "/" + props.id)
            .then((response) => {
                // console.log(response.data.status)
                if (response.data.status === 200) {
                    // console.log("Deleted")
                    axiosPublic.get(requests.fetchSuccessStories)
                        .then((response) => {
                            // console.log(response.data.data);
                            props.successStories(response.data.data)

                        })
                }
                else {
                    // console.log("Not Deleted")
                }
            })
            .catch((response) => {
                // console.log(response)
            })

    }

    return (
        <>

            <div className="card rounded shadow">

                <img className="card-img-top p-2" src={"http://10.179.2.158:8080/" + props.location} height={150} width={150} alt='Test' />
                <h4 className='card-title text-center text-uppercase pt-2'>{props.title}</h4>
                <div className="card-block">

                    <div className="meta">
                        {/* <a href="#">Friends</a> */}
                    </div>
                    <div className="mx-2 card-text large_text mb-3" >
                        {props.desc}
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-between">
                    <div className="my-auto mx-3">{props.createdDate}</div>
                    <div className="my-auto">{props.districtName}</div>
                    <div >
                        <button className='btn' onClick={(e) => handleEdit(e, props)}> <Edit ></Edit></button>
                        <button className='btn' onClick={(e) => handleDelete(e, props)}><Delete></Delete></button>
                    </div>



                    {/* <a className="text-dark"  href={props.download} target="_blank" rel="noreferrer"><RemoveRedEyeIcon ></RemoveRedEyeIcon></a> */}
                </div>
            </div>


            <ReLoginModal showModal={show}></ReLoginModal>
        </>

    )
}

export default SuccessStoriesAdminCard