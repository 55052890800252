import React from 'react'
import { Modal } from 'react-bootstrap'
import {Button} from 'react-bootstrap'
import axios from '../../helper/axios_authenticated_urls'
import requests from '../../helper/request'

function DeactivateModal({showDeactivateModal,handleDeactivateModalClose,user}) {

    const deactivate = async ()=>{
     const res  = await  axios.patch(requests.fetchAllUsers+"/"+user.id)
     if(res.data.status ===200){
        handleDeactivateModalClose(false);
     }
     
    }
    
    
  return (
    
    <div>     

<Modal show={showDeactivateModal} onHide={handleDeactivateModalClose} centered backdrop="static">
    <Modal.Header >
        <Modal.Title className='text-center text-uppercase'> {user?user.isActive?"Deactivate":"Activate":null} the User</Modal.Title>
    </Modal.Header>
    <Modal.Body>

    <p> Are You Sure You want to Deactivate the User <br></br> User Name: {user?user.userName:null} <br></br> Name: {user?user.name:null}</p>
    </Modal.Body>
    <Modal.Footer >
        
        

        <Button onClick={handleDeactivateModalClose}>Close</Button>
        <Button onClick={deactivate}>{user?user.isActive?"Deactivate":"Activate":null}</Button>
    </Modal.Footer>
</Modal>
</div>)
    
  
}

export default DeactivateModal