import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal, Toast } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from 'react'
import { loginUser } from '../../services/user-service';
import { doLogin } from '../../auth/auth';
import { ToastContainer, toast } from 'react-toastify';
import requests from '../../helper/request';
import BounceLoader from "react-spinners/BounceLoader";
import { ratingClasses } from '@mui/material';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};
/**
 * 
 */
const LoginModal = ({ showModal, setShow, handleClose, setloginState, captchaImage, setimg }) => {

    const navigate = useNavigate();
    const [error, seterror] = useState(false);
    let [loading, setLoading] = useState(false);


    const [imgCaptcha, setimgCaptcha] = useState(null);

    const [loginDetail, setloginDetail] = useState(
        {
            userName: '',
            password: ''
            // captcha: ''
        }
    )

    const handleChange = (event, field) => {

        let data = event.target.value;
        seterror(false);

        setloginDetail({
            ...loginDetail,
            [field]: data
        })
    }

    // const handleRegenarateCaptcha =(event)=>{

    //     event.preventDefault();


    //      fetch("http://"+window.location.host+"/fishFarmerPortal/public"+requests.fetchCaptcha)
    //     // fetch("http://10.179.2.92:8080/public"+requests.fetchCaptcha)
    //       .then((response) => response.json())
    //       .then((data) => {setimgCaptcha(data.data.captchaString);  sessionStorage.setItem("public_key", data.data.publicKey); console.log(data)} );
    //     return;
    // }


    const handleHideModal = () => {

        if (loginDetail != null) {

            loginDetail.userName = '';
            loginDetail.password = '';
            // loginDetail.captcha = '';

            if (error != null) {
                seterror(false);
            }
        }

        handleClose();
    }

    const handleSubmit = (event) => {


        event.preventDefault();
        // console.log(loginDetail)
        setLoading(true)

        if (loginDetail.userName === '' || loginDetail.password === '') {

            // console.log("Uername name is empty")
            // console.log(loginDetail.userName.trim())
            seterror("Invalid Credential")
            setLoading(false)
            return;
        }

        //submit form to the server       
        loginUser(loginDetail, seterror).then((userData) => {
            //save the user data and token to the local storage

            doLogin(userData.data, () => {
                //This is the place where you will write the logic to be performed after login
                // console.log("login data stored")
                seterror(false);
                navigate("/dashboard/home");
                setShow(false);
                setloginState(true);
                setLoading(false)
            })


            loginDetail.userName = '';
            loginDetail.password = '';
            // loginDetail.captcha = '';
            seterror(false);
            setLoading(false)


        }).catch(error => {
            setShow(true)
            setLoading(false)
            // seterror("Invalid Credential");
            return;

        })
    }

    return <>

        <Modal show={showModal} onHide={handleHideModal} className="login-form">
            <Modal.Header closeButton >

                <Modal.Title >Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error ? <div class="alert alert-danger  fade show" role="alert">
                    {<strong>{error}</strong>}

                </div> : null}
                <form action="index.html" autocomplete="OFF">

                    <div className="inputb">
                        <label htmlFor="email">User Name</label>
                        <input className="forminput"
                            type="text"
                            id="userName"
                            placeholder='Enter your User Name:'
                            value={loginDetail.userName}
                            onChange={(event) => handleChange(event, 'userName')}></input>

                    </div>

                    <div className="inputb">
                        <label htmlFor="password">Password</label>
                        <input className="forminput hide-pwd"
                            type="password"
                            id="password"
                            placeholder='Enter your Password'
                            value={loginDetail.password}
                            onChange={(event) => handleChange(event, 'password')}></input>

                    </div>


                </form>
            </Modal.Body>
            <Modal.Footer >
                {/* <Button variant="secondary" onClick={handleClose} className="loginbtn">
                            Close
                        </Button> */}
                {loading ?
                    <BounceLoader
                        loading={loading}
                        color={"#133c5e"}
                        cssOverride={{

                            display: "block",
                            margin: "0 auto",
                            borderColor: "red",
                        }}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    /> : <Button variant="primary" onClick={handleSubmit} className="loginbtn">
                        Login
                    </Button>}
            </Modal.Footer>

        </Modal>


    </>
}

LoginModal.propTypes = propTypes;
LoginModal.defaultProps = defaultProps;
// #endregion

export default LoginModal;