import axios from "axios"
import JSEncrypt from "jsencrypt"
import forge from "node-forge"
export const loginUser = (loginDetails, seterror) => {
  let encryptedPassword

  let encryptPassword = () => {
    const key = `-----BEGIN PUBLIC KEY-----
    ${sessionStorage.getItem("public_key")}
    -----END PUBLIC KEY-----`

    const publicKey = forge.pki.publicKeyFromPem(key)
    // let publicKey = s;
    encryptedPassword = forge.util.encode64(
      publicKey.encrypt(loginDetails.password, "RSA-OAEP", {
        md: forge.md.sha256.create(),
      })
    )
  }

  let encrypt = new JSEncrypt()

  encrypt.setPublicKey(sessionStorage.getItem("public_key"))
  // let encrypted = encrypt.encrypt(loginDetails.password);
  encryptPassword()
  let userData = { ...loginDetails }

  // console.log(encryptedPassword)
  // console.log(userData.password = encryptPassword())
  userData.password = encryptedPassword

  return axios.post(window.location.protocol + "//" + window.location.host + '/fishFarmerPortal/public/login', userData, {
    // return axios.post("http://localhost:8080/public/login", userData, {
    // TODO: remove comment for headers
    headers: {
      userName: userData.userName,
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      // console.log(error.response.status);
      if (error.response.status === 401) {
        seterror("Access Denied! Try with Correct Credentials")
      } else if (error.response.status === 403) {
        seterror("Password or UserName is incorrect")
      }

      // return (
      //     error.response.data.error.code
      //     //  console.log(error.response.data.error.code)
      // )
    })
}
