import axios from "axios"
import { getToken } from "../auth/auth"
// import { Modal } from "react-bootstrap";
// import { useNavigate } from 'react-router-dom'

const instance = axios.create({
  /*This is the base url that will be concatinated later and create in an inbuilt function of axios*/
  baseURL: window.location.protocol + "//" + window.location.host + "/fishFarmerPortal/",
  //baseURL: "https://fisheries.meghalaya.gov.in/fishFarmerPorta/",
  // baseURL: "http://10.179.2.92/fishFarmerPortal/",
  // baseURL: "http://localhost:8080/",
})

instance.interceptors.request.use((request) => {
  if (getToken() != null) {
    let token = getToken()
    request.headers.Auth = `Bearer ${token}`
  }
  // console.log(request);
  return request
})

instance.interceptors.response.use((response) => {
  // console.log(response);
  return response
})

export default instance
