import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';
import AdminPondDetailsDashboard from './AdminPondDetailsDashboard';
import PondDetailsDistrictDashBoard from './PondDetailsDistrictDashBoard';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const PondDetails = () => {

    const [showModal, setShow, districtCode, userRole] = useOutletContext();

    return <div>
        {districtCode ? <PondDetailsDistrictDashBoard districtCode={districtCode} showModal={showModal} setShow={setShow} userRole={userRole}></PondDetailsDistrictDashBoard>
            : <AdminPondDetailsDashboard districtCode={districtCode} showModal={showModal} setShow={setShow} userRole={userRole}></AdminPondDetailsDashboard>}
    </div>
}

PondDetails.propTypes = propTypes;
PondDetails.defaultProps = defaultProps;
// #endregion

export default PondDetails;