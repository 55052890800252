import { ArrowBack } from '@mui/icons-material';
import React from 'react'
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import ReLoginModal from '../commons/ReLoginModal';
import axios from '../../helper/axios_authenticated_urls';
import requests from '../../helper/request';

function NotificationUpdateForm() {

  const [show, setShowRelogin] = useState(false);
  const handleShowReloginModal = () => setShowRelogin(true);

  const location = useLocation();
  const navigate = useNavigate();

  const [fileSelected, setfileSelected] = useState(null);
  const [imageUpload, setimageUpload] = useState(false)
  const [fileError, setfileError] = useState(false);
  const [error, seterror] = useState(false);
  const [fileErrorMsg, setfileErrorMsg] = useState("");

  const [notification, setNotification] = useState(
    {
      title: location.state ? location.state.title : "",
      description: location.state ? location.state.description : "",
      typeOfNotification: location.state ? location.state.typeOfNotification : ""
    }
  );

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  // }

  const handleChange = (event, field) => {

    seterror(false);
    setfileError(false);
    const value = event.target.value;
    setNotification({
      ...notification,
      [field]: value
    })
    // console.log(data)
  }

  // const handleImageEditButton = (e) => {
  //   // console.log("first")
  //   e.preventDefault()
  //   if (imageUpload) {
  //     setimageUpload(false)
  //   }

  //   else {
  //     setimageUpload(true)
  //   }

  // console.log("first")

  // }

  const handleCreate = (event) => {
    // event.preventDefault()
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "file",
      fileSelected
    );
    formData.append(
      "notification",
      JSON.stringify(notification)
    )

    // Request made to the backend api
    // Send formData object

    if (notification.title === "" || notification.description === "" || notification.typeOfNotification === "") {
      seterror(true);
      return;
    }
    // console.log("File:", fileSelected)
    axios.post(requests.fetchNotification, formData)
      .then((response) => {
        if (response.data.status === 200) {
          navigate("/admin/notification")
          // console.log(response)
        }
        else if (response.data.status === 401 || response.data.status === 403) {
          handleShowReloginModal();
        }
      })
  }

  // const handleUpdate = (event) => {
  //   event.preventDefault()
  // }

  const handleFileSelect = (event, field) => {

    seterror(false);
    setfileError(false);

    event.preventDefault();
    const data = event.target.value;
    // console.log(data)

    // setsuccessStories({
    //     ...successStories,
    //     [field]: data
    // })
    if (event.target.files[0].size / 10000000 >= 2) {
      setfileError(true)
      setfileErrorMsg("File size should be less than 2 M.B.")
      return;
    }
    if (event.target.files[0].type === "application/pdf") {

      // console.log(event.target.files[0].type)
      setfileSelected(event.target.files[0]);
    }
    else {

      setfileError(true)
      setfileErrorMsg("File extension should be pdf")
      return;
    }

  }

  return (
    <>
      <div className='container'>
        {error ? <div class="alert alert-danger" role="alert">
          Please Check all the fields
        </div> : null}
        <div className='card-header text-center text-uppercase fw-bold mt-2 p-2'>
          {location.state ? "Notification Update" : "Add New Notification"}
        </div>
        <div className='m-2 '>
          <ArrowBack onClick={() => navigate('/admin/notification')}></ArrowBack>
          <span className='btn btn-sm' onClick={() => navigate('/admin/notification')}>Back</span>
        </div>
        <div className='card-body shadow rounded p-3'>
          <form>
            <div className="row">
              <div className="col-md-6 col-lg-6 mt-2">
                <label htmlFor='title' className='fw-bold'>Title</label>
                <input type="text"
                  className="form-control"
                  placeholder="Title"
                  name='title'
                  aria-label="Title"
                  value={notification.title}
                  onChange={(e) => handleChange(e, "title")} />
              </div>
              <div className="col-md-6 col-lg-6 mt-2">
                <label htmlFor='description' className='fw-bold'>Notification</label>
                <input type="text"
                  className="form-control"
                  placeholder="Description"
                  aria-label="Description"
                  name='description'
                  value={notification.description}
                  onChange={(e) => handleChange(e, "description")}
                />
              </div>
              <div className="col-md-6 col-lg-6 mt-2">
                <label htmlFor='typeOfNotification' className='fw-bold'>Type of Notification</label>
                <select className='form-control' value={notification.typeOfNotification}
                  onChange={(e) => handleChange(e, "typeOfNotification")}>
                  <option value="" disabled>
                    --Select an option--
                  </option>
                  <option value={"Tender"}>Tender</option>
                  <option value={"Order"}>Order</option>
                  <option value={"Notification"}>Notification</option>
                  <option value={"Other"}>Other</option>
                </select>
              </div>
              {location.state ? imageUpload ? <div className="my-3">
                <label for="formFile" class="form-label fw-bold">Upload the image</label>
                <input className="form-control" type="file" id="formFile" onChange={(event) => handleFileSelect(event)} />
                {fileError ? <div class="alert alert-danger" role="alert">
                  {fileErrorMsg}
                </div> : null}
              </div> : null : <div className="my-3">
                <label for="formFile" class="form-label fw-bold">Upload the image</label>
                <input className="form-control" type="file" id="formFile" onChange={(event) => handleFileSelect(event)} />
                {fileError ? <div class="alert alert-danger" role="alert">
                  {fileErrorMsg}
                </div> : null}
              </div>}
            </div>
            {location.state ?
              <>
                {/* <button className='btn btn-primary' onClick={(event) => handleUpdate(event)}>Update</button> */}
                {/* <button className='btn btn-primary btn-sm mx-3 my-2' onClick={((e) => handleImageEditButton(e))}>{imageUpload ? "Close Upload Image" : "Edit Image"}</button> */}
              </> :
              <button className='btn btn-primary btn-sm' onClick={(event) => handleCreate(event)}>Create</button>}
            <div className='col-md-6 float-end ' style={{ maxHeight: "300px" }}>

            </div>
          </form>
        </div>
        <ReLoginModal showModal={show}></ReLoginModal>

      </div>
    </>

  )
}

export default NotificationUpdateForm