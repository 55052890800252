import React from "react"
// import PropTypes from "prop-types"
import CustomCarousel from "./CustomCarousel"
import neOneStopLogo from "../../images/NEoneStoplogo.png"
import { useNavigate, Navigate } from "react-router-dom"
import { isLoggedIn } from "../../auth/auth"

import PMKisan from "../../images/CreativeofQRcodes.jpg"
import newGif from "../../images/newGif.gif"
import nfdb from "../../images/nfdb_logo.png"
import cifaLogo from "../../images/CIFA_logo.png"
import cifriLogo from "../../images/cifri_logo.png"
import mpeda from "../../images/MPEDA_logo.png"
import emblem from "../../images/emblem-dark.png"
// Dont delete this two import otherwise there will be an error for chart.js
import BarChart from "../commons/BarChart"
import LineChart from "../commons/LineChart"
import Marquee from "react-fast-marquee"
import axios from "axios"
import NotificationSection from "./notification/NotificationSection"
import "../landingpage/css/landingpage.css"
import requests from '../../helper/request';
import { useEffect, useState } from 'react'
import instance from "../../helper/axios"

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */
const Home = () => {


  const [notificationDetails, setNotificationDetails] = useState([]);

  useEffect(() => {

    let getNotitfication = async () => {

      const notification = await instance.get(requests.fetchNotification);
      setNotificationDetails(notification.data.data)
      // console.log(notificationDetails)

    }
    getNotitfication()

  }, [])

  const handleShowPdf = (fileName) => {
    instance.get(requests.fetchNotification + "/" + fileName, {
      responseType: "blob"
    })
      .then((response) => {
        const fileURL = window.URL.createObjectURL(response.data)
        const link = document.createElement("a")
        link.href = fileURL
        link.target = "_blank"
        link.click()
      })
  }

  // PM Kisan pop up
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {/* PM KISAN */}
      {/* {showPopup && (
        <div className="modal-overlay">
          <div className="modal-container">
            <button className="close-button" onClick={handleClosePopup}>
              <b>X</b>
            </button>
            <img className="modal-image" src={PMKisan} alt="PM KISAN" />
          </div>
        </div>
      )} */}
      {/* Air Force Notification */}
      {/*{showPopup && (
        <div className="modal-overlay">
          <div className="modal-container">
            <button type="button" class="btn-close float-end" onClick={handleClosePopup} />
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-body">
                  <h2 className="text-center modal-title fw-bolder text-dark" id="popModalLable">Indian Air Force Recruitment</h2>
                  <hr />
                  <h5 className="text-center mt-4 fw-bold">INDIAN AIR FORCE INVITES ONLINE APPLICATIONS FROM UNMARRIED INDIAN MALE AND FEMALE CANDIDATES FOR SELECTION TEST FOR AGNIVEERVAYU INTAKE 01/2025 UNDER AGNIPATH SCHEME</h5>
                  <h5 className="mt-4">Online Registration date: From 11 AM on 17th January to 11 PM on 06 February 2024</h5>
                  <h5 className="mt-4">For More Information, check website<a className="fw-bold text-dark" target="_BLANK" href="https://agnipathvayu.cdac.in">https://agnipathvayu.cdac.in</a></h5>
                  <h4 className="fw-bold mb-0 mt-4">Downloads:-</h4>
                  <a className="fw-bold text-dark text-decoration-none d-block" target="_BLANK" href='documents/Notification/SHORTNOTIFICATION.pdf' download>1. Short Notification</a>
                  <a className="fw-bold text-dark text-decoration-none d-block" target="_BLANK" href='documents/Notification/FULLNOTIFICATION.pdf' download>2. Full Notification</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}*/}

      {
        isLoggedIn() ? (
          <Navigate to={"/dashboard/home"}></Navigate>
        ) : (
          <div>
            <CustomCarousel></CustomCarousel>

            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="mt-3 border-0 font-weight-bold">
                    <div className=" text-center text-uppercase p-2 fw-bold">
                      Introduction
                    </div>
                    <div className="mt-2">
                      Fisheries and Aquaculture constitute a source of livelihood
                      to millions of people all over the world. Faced with the
                      challenges to cater to the ever growing demand of food to a
                      population which is expected to reach 9 billion by the
                      twenty first century, sustainable development goals are the
                      need of the hour. In Meghalaya, Fisheries plays a very
                      important role not only as a source of protein for human
                      consumption but also in creating livelihoods to the farmers
                      through various income generating activities right from fish
                      culture, marketing and value chain management through
                      ornamental and recreational fisheries, and tourism.
                      Realizing the importance of Fisheries toward sustainable
                      development, “Meghalaya State Aquaculture Mission’ was
                      launched in March 2012, to uplift the economy through fish
                      farming, with the slogan “from dependence to
                      self-sufficiency’.
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="card mt-3">
                      <div className="card-header text-center text-uppercase p-2 fw-bold my-bg-primary text-white">
                        Notification
                      </div>

                      <div className="card-body mt-1 marque_section">
                        <div
                          direction="up"
                          className="moving-text"
                          style={{ maxHeight: "250px" }}
                        >

                          {
                            notificationDetails?.map((notification) => {
                              return (
                                < div key={notification.id} className=" border-bottom my-3" onClick={() => handleShowPdf(notification.fileName)}>
                                  <strong>
                                    {new Date(notification.createdDate).getDate().toString().padStart(2, '0')}{" "}
                                    {new Date(notification.createdDate).toLocaleString('en-US', { month: 'long' })}{" "}
                                    {new Date(notification.createdDate).getFullYear()}
                                  </strong>
                                  {notification?.isNew ? <img src={newGif} /> : null}
                                  <br />
                                  {notification.description}
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr></hr>

              <Marquee
                className="my-3 d-flex justify-content-around"
                pauseOnHover={true}
                speed="20"
              >
                <div className="mx-3 text-center">
                  <a
                    className="text-dark"
                    href="https://dof.gov.in/"
                    target="_blank"
                    rel="noreferrer"
                    alt="Advancing north east"
                  >
                    <p>Department of Fisheries,Government of India</p>
                  </a>

                  <img
                    className="text-center"
                    src={emblem}
                    alt="Advancing North East"
                    height={100}
                  ></img>
                </div>

                <div className="mx-3 text-center">
                  <a
                    className="text-dark"
                    href="https://www.advancingnortheast.in/"
                    target="_blank"
                    rel="noreferrer"
                    alt="Advancing north east"
                  >
                    <p>Advancing North East</p>
                  </a>

                  <img
                    className="text-center"
                    src={neOneStopLogo}
                    alt="Advancing North East"
                    height={100}
                  ></img>
                </div>
                <div className="mx-3 text-center">
                  <a
                    className="text-dark text-center"
                    href="https://nfdb.gov.in/"
                    target="_blank"
                    rel="noreferrer"
                    alt="Advancing north east"
                  >
                    <p>National Fisheries Development Board</p>
                  </a>

                  <img
                    className="text-center"
                    src={nfdb}
                    alt="Advancing North East"
                    height={100}
                  ></img>
                </div>
                <div className="mx-3 text-center">
                  <a
                    className="text-dark"
                    href="https://cifa.nic.in/"
                    target="_blank"
                    rel="noreferrer"
                    alt="Central Institute of Freshwater Aquaculture"
                  >
                    <p>Central Institute of Freshwater Aquaculture</p>
                  </a>

                  <img
                    className="text-center"
                    src={cifaLogo}
                    alt="Advancing North East"
                    height={100}
                  ></img>
                </div>
                <div className="mx-3 text-center">
                  <a
                    className="text-dark"
                    href="http://cifri.res.in/"
                    target="_blank"
                    rel="noreferrer"
                    alt="ICAR-Central Inland Fisheries Research Institute"
                  >
                    <p>ICAR-Central Inland Fisheries Research Institute</p>
                  </a>

                  <img
                    className="text-center"
                    src={cifriLogo}
                    alt="Advancing North East"
                    height={100}
                  ></img>
                </div>
                <div className="mx-3 text-center">
                  <a
                    className="text-dark"
                    href="https://mpeda.gov.in/"
                    target="_blank"
                    rel="noreferrer"
                    alt="ICAR-Central Inland Fisheries Research Institute"
                  >
                    <p>Marine Products Export Development Authority</p>
                  </a>

                  <img
                    className="text-center"
                    src={mpeda}
                    alt="Advancing North East"
                    height={100}
                  ></img>
                </div>
              </Marquee>
            </div>
          </div >
        )
      }
    </>
  )
}

Home.propTypes = propTypes
Home.defaultProps = defaultProps
// #endregion

export default Home
