import React from 'react';
// import styled from 'styled-components';
// import PropTypes from 'prop-types';
import { Bar } from "react-chartjs-2";

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const BarChart = ({ chartData }) => {

  const options = {
    indexAxis: 'x',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        generateLabels: (chart) => {
          console.log(chart)
        }
      },
      title: {
        display: false,

      },
    },
  };
  return <>
    <p className="text-center ">
      Farmer Registered Month wise
    </p>
    <Bar data={chartData} options={options}></Bar>
  </>;
}

BarChart.propTypes = propTypes;
BarChart.defaultProps = defaultProps;
// #endregion

export default BarChart;