import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axiosProtected from '../../../helper/axios_authenticated_urls'
import requests from '../../../helper/request';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate, Navigate, Route } from 'react-router-dom';
import ReLoginModal from '../../commons/ReLoginModal';



// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */

const VillageDashboardForPond = () => {


    const location = useLocation();

    const navigate = useNavigate();

    const [details, setdetails] = useState([]);

    const [countOfFarmersByBlock, setcountOfFarmersByBlock] = useState();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const columns = [
        // { field: 'villageCode', headerName: 'Village Code', width: 150 },
        { field: 'villageName', headerName: 'Village Name', width: 150 },
        { field: 'countOfFarmers', headerName: 'Count of Farmers', width: 150, editable: false },

        {
            field: 'Select',
            headerName: 'Select',
            width: 100,
            renderCell: (params) =>
                <button className='btn btn-sm btn-warning'
                    onClick={() => {
                        navigate("../ponddetails/fishfarmerdetailswithponds",
                            { state: { id: params.row.villageCode, name: params.row.villageName } })
                    }}>
                    View Details
                </button>
            ,
            disableClickEventBubbling: true,
        },
    ];

    // This is the call to the api for getting the data for the village
    useEffect(() => {

        if (location.state.id) {

            let getBlockWiseFarmer = async () => {
                await axiosProtected.get(requests.fetchPondForAllVilalgesOfBlock + "/" + location.state.id).then(
                    (res) => {

                        setdetails(res.data.data);
                        //console.log(res);
                    }
                )
                    .catch(error => {
                        if (error.response.status === 401 || error.response.status === 500 || error.response.status === 403) {

                            handleShow()

                        }
                    }

                    )

            }
            getBlockWiseFarmer();
        }

    }, [location.state.id]);


    useEffect(() => {

        if (location.state.id) {

            let getBlockWisePond = async () => {
                await axiosProtected.get(requests.fetchPondCountByBlock + "/" + location.state.id).then(
                    (res) => {

                        setcountOfFarmersByBlock(res.data.data);
                        // console.log(res);
                        // console.log(location.state.show)
                    }
                )
                    .catch(error => {
                        if (error.response.status === 401 || error.response.status === 500 || error.response.status === 403) {
                            // debugger;
                            handleShow();
                            // console.log("object");
                            // debugger;


                        }


                    }

                    )

            }
            getBlockWisePond();
        }
    }, [location.state.id]);


    return (
        <>
        <div className='card bg-light body mt-3 p-3'>
            <div className=' text-center h5'> Projects Registered in Different Villages in {location.state.name} Block</div>
        </div>
   
    <div className='row'>

        <div className='col-lg-3 col-sm-12 col-md-12 my-auto'>
            <div className="bg-light py-4 b-dbcard shadow-lg my-2">
                {/* { <props.Icon fontSize='large' ></props.Icon>} */}


                <h5 className='text-center'>Block: {location.state.name}</h5>
                <hr></hr>
                <div className="text-left ">
                    <p className="px-5 h4">Registered Projects <span className="float-right">{countOfFarmersByBlock}</span></p>

                </div>


            </div>
        </div>


        <div className='container-fluid mb-5 col-lg-3 col-md-12 col-sm-12 mt-5' style={{ height: "70vh", width: '75%' }}>
            <DataGrid
                getRowId={(row) => row.villageCode}
                rows={details}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}

                disableSelectionOnClick
                editable={false}
                // components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </div>

        {/* <div className="App">
      <table>
        <tr>
          <th>villageName</th>
          <th>countOfFarmers</th>
           <th>Gender</th>
          <th>Name</th>
          <th>Age</th>
          <th>Gender</th> 
        </tr>
        {details.map((val, key) => {
          return (
            <tr key={key}>
              <td>{val.villageName}</td>
              <td>{val.countOfFarmers}</td>
             <td>{val.age}</td>
              <td>{val.gender}</td>
              <td>{val.pondId}</td>
              <td>{val.pondLocationDistrict}</td> 
            </tr>
          )
        })}
      </table>
    </div> */}
        <ReLoginModal showModal={show}></ReLoginModal>
    </div>     </>);
}

VillageDashboardForPond.propTypes = propTypes;
VillageDashboardForPond.defaultProps = defaultProps;
// #endregion

export default VillageDashboardForPond;