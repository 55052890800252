import React from 'react'

function PrivacyPolicy() {
  return (
    <div className='container '>
      <h4 className='mt-3 py-3 text-center text-uppercase'> Privacy Policy</h4>
      <div className='border p-3 rounded shadow'>
        <p>
        Thanks for visiting the website of the Fisheries Department, Government of Meghalaya, and reviewing our private policy. We collect no personal information, like names or address, when you visit our website. If you chose to provide that information to us, it is only use to fulfill your request for information. We do not collect personal information for any purpose other than to respond to you (for example, to respond to your questions or provide subscriptions you have chosen). We only share the information you give us with another Government agency if your question relates to that agency, or as otherwise required by law. Our website never collects information or creates individual profiles for commercial marketing. While you must provide an e-mail address for a localised response to any incoming questions or comments to us, we recommend that you do NOT include any personal information.

 
        </p>
      </div>
     
    </div>
  )
}

export default PrivacyPolicy