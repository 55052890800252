import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import '../district/css/table.css'

import { useState, useEffect } from 'react';
import axios from '../../../helper/axios';
import requests from '../../../helper/request';
import axiosProtected from '../../../helper/axios_authenticated_urls'
import { getOfficeLocation } from '../../../services/user-details';
import LoadingSpinner from '../../commons/LoadingSpinner';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

const columns = [
    { field: 'fishFarmerId', headerName: 'Fish Farmer Id', width: 150 },
    { field: 'epicNumber', headerName: 'Epic Number', width: 90 },
    { field: 'name', headerName: 'Name', width: 150, editable: false },
    { field: 'gender', headerName: 'Gender', width: 150, editable: false },
    { field: 'age', headerName: 'Age', type: 'number', width: 110, editable: true },
    { field: 'dob', headerName: 'Date of Birth', width: 150, type: "date",valueGetter: ({ value }) => value && new Date(value),  },
    { field: 'farmerId', headerName: 'Farmer Id', width: 150, editable: false },
    { field: 'locality', headerName: 'Locality', width: 150, editable: false },
    { field: 'villageName', headerName: 'Village name', width: 150, editable: false },
    { field: 'blockName', headerName: 'Block Name', width: 150, editable: false },
    { field: 'farmerMotherOrHusbandName', headerName: 'Father/Mother/Husband Name', width: 150, editable: false },
    { field: 'landOwnershipTypeId', headerName: 'Land Ownership Type', width: 150, editable: false },


];

/**
 * 
 */
const BlockSelectionComponent = (props) => {


    const [blocks, setblocks] = useState([]);
    const [selectedBlock, setSelectdBlock] = useState();
    const [details, setdetails] = useState([]);
    const [blockName,setBlockName] = useState(false);
    const [showTable, setshowTable] = useState(false);
    

    useEffect(() => {

        let getBlocks = async () => {
            let blocks = await axios.get(requests.fetchBlocks + "/" + props.districtCode);
            setblocks(blocks.data.data);
            // console.log(blocks.data.data)

        }

        getBlocks();
    }, []);


    useEffect(() => {

        let getBlocks = async () => {
            let blocks = await axios.get(requests.fetchBlocks + "/" + props.districtCode)
            setblocks(blocks.data.data);
            setshowTable(false)
            // console.log(blocks.data.data)

        }

        getBlocks();
    }, [props.districtCode]);

    useEffect(() => {

        if (selectedBlock) {

            let getBlockWiseFarmer = async () => {
                await axiosProtected.get(requests.fetchFarmerBlockWise + "/" + selectedBlock).then(
                    (res) => {
                        // debugger

                        //   setSelectdBlock(blocks.data.data);
                        // console.log(blockWiseFarmers.data.data)
                        setdetails(res.data.data);
                        setshowTable(true)
                        // console.log(details);
                    }
                )
                    .catch(error => {
                        if (error.response.status === 401 || error.response.status ===500 || error.response.status ===403) {
                            // console.log("object")
                            props.setShow(true)


                        }
                    }

                    )
            }
            getBlockWiseFarmer();
        }



    }, [selectedBlock]);

    return <div className='container'>
        <p className='text-center my-3 h5'> Select Block for {props.districtName ? props.districtName : getOfficeLocation()} </p>
        <div className='d-flex justify-content-center my-3 text-color-secondary'>
            <div className='row'>
                <div className='col-md-4'>
                    <label htmlFor='dropdown-basic-button'>
                        Blocks:
                    </label>
                </div>
                <div className='col-md-8'>
                    <select className='form-control' id="dropdown-basic-button" title="Blocks" value={selectedBlock}
                        onChange={(e) => {
                            // setShowDistrictDashBoard(true);
                            setSelectdBlock(e.target.value);
                            //  let select = e.target;
                            //  console.log(select.options[e.selectedIndex].text)
                            //  setBlockName(select.options[e.selectedIndex].text) ;

                        }}>
                        <option value="" selected disabled>Blocks</option>
                        {
                            blocks.map((block) => {

                                return <option key={block.blockCode} value={block.blockCode}>{block.blockName}</option>

                            })
                        }

                    </select>

                </div>

            </div>


        </div>
        <hr></hr>

        <div className='my-3' style={{ height: "65vh", width: '100%' }}>
            {
                blockName ? <div className='my-3 h4 text-center'> Selected Block: {blockName}</div>:null
                // console.log(blockName)
            }

            {showTable ? 
            
            <DataGrid
                getRowId={(row) => row.epicNumber}
                rows={details}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}

                disableSelectionOnClick
                editable={false}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            /> : null}
        </div> 

{/* <div className="App">
      <table>
        <tr>
          <th>Fish FArmer Id</th>
          <th>Epic</th>
          <th>AGe</th>
          <th>Name</th>
          <th>Gender</th>
          <th>Village</th>
          <th>Block</th>
         
          <th>Locality</th>
        </tr>
        {details.map((val, key) => {
          return (
            <tr key={key}>
              <td>{val.fishFarmerId}</td>
              <td>{val.epicNumber}</td>
              <td>{val.age}</td>
              <td>{val.name}</td>
              <td>{val.gender}</td>
              <td>{val.villageName}</td>
              <td>{val.blockName}</td>
             
              <td>{val.locality}</td>
            </tr>
          )
        })}
      </table>
    </div> */}
    </div>;
}

BlockSelectionComponent.propTypes = propTypes;
BlockSelectionComponent.defaultProps = defaultProps;
// #endregion

export default BlockSelectionComponent;