import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import axiosProtected from "../../../helper/axios_authenticated_urls"
import requests from "../../../helper/request"
import femaleIcon from "../../../images/femalefarmer.png"
import maleIcon from "../../../images/maleFarmer.png"
import { Pie } from "react-chartjs-2"
import districtIcon from "../../../images/cityscape.png"
import axios from "../../../helper/axios"
import { Person } from "@mui/icons-material"

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */
const DistrictHomeDashBoard = ({ setShow, districtCode, districtName }) => {
  const [fishFarmerCount, setfishFarmerCount] = useState([])
  const [farmerCategoryCount, setfarmerCategoryCount] = useState([])
  const [districtFishFarmerGenderCount, setdistrictFishFarmerGenderCount] =
    useState([])
  const [districtWiseServicePlusData, setDistrictWiseServicePlusData] =
    useState([])

  useEffect(() => {
    let getFarmersDistrictWise = async () => {
      await axiosProtected
        .get(requests.fetchFishFarmerCountDistrictWise + `/${districtCode}`)
        .then((res) => {
          setfishFarmerCount(res.data.data)
        })
        .catch((error) => {
          if (
            error.response.status === 401 ||
            error.response.status === 500 ||
            error.response.status === 403
          ) {
            // console.log("object")

            setShow(true)
          }
        })
    }

    getFarmersDistrictWise()
    // fetchData();
  }, [districtCode])


  useEffect(() => {
    let getFarmerCategoryCount = async () => {
      let farmerGender = await axiosProtected.get(
        requests.fetchFishFarmerCountByGenderDistrictWise + `/${districtCode}`
      )

      setdistrictFishFarmerGenderCount(farmerGender.data.data)
    }
    getFarmerCategoryCount()

    // console.log(farmerCategoryCount)
  }, [districtCode])

  useEffect(() => {
    let getFarmerCategoryCount = async () => {
      let farmerCategory = await axiosProtected.get(
        requests.fetchFishFarmerCountByCategoryDistrictWise + `/${districtCode}`
      )

      // console.log(farmerCategory.data);
      setfarmerCategoryCount(farmerCategory.data)
    }
    getFarmerCategoryCount()

    // console.log(farmerCategoryCount)
  }, [districtCode])

  useEffect(() => {
    let servicePlusDistrictData = async () => {
      let servicePlusData = await axios.get(
        requests.fetchDistrictWiseServicePlusDetails + `/${districtCode}`
      )

      //console.log(servicePlusData);
      setDistrictWiseServicePlusData(servicePlusData.data.data)
    }

    servicePlusDistrictData()
  }, [])

  let data = {
    labels: farmerCategoryCount?.data?.map((x) => x.category),
    datasets: [
      {
        // label: `${farmerCategoryCount?.category?.length} Coins Available`,
        data: farmerCategoryCount?.data?.map((x) => x.count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderWidth: 1,
      },
    ],
  }

  var options = {
    maintainAspectRatio: false,
    scales: {},
    legend: {
      labels: {
        fontSize: 25,
      },
    },
  }

  return (
    <div className="container">
      {/* Content Row */}
      <div className="my-5" id="b-homedb">
        <div> </div>
        <div className="">
          <h4 className="text-center mb-3">
            Details for {districtName} district
          </h4>
          <div className="row">
            <div className="col-lg-3  col-sm-8">
              <div className="py-4 b-dbcard shadow-lg my-3 text-center number_submitted rounded border_radius">
                {/* { <props.Icon fontSize='large' ></props.Icon>} */}

                <h4 className="text-center text-uppercase text-white fw-bold h2">
                  {" "}
                  {districtWiseServicePlusData.submitted}
                </h4>
                <div className="text-left ">
                  <p className="px-5">
                    {" "}
                    <span className="float-right text-white">
                      Application Submitted
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3  col-sm-8">
              <div className="bg-light py-4 b-dbcard shadow-lg my-3 text-center rounded border_radius">
                {/* { <props.Icon fontSize='large' ></props.Icon>} */}

                <h4 className="text-center text-uppercase fw-bold h2">
                  {" "}
                  {districtWiseServicePlusData.rejected}
                </h4>
                <div className="text-left ">
                  <p className="px-5">
                    {" "}
                    <span className="float-right">Application Rejected</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3  col-sm-8">
              <div className="py-4 b-dbcard shadow-lg my-3 text-center number_delivered rounded border_radius">
                {/* { <props.Icon fontSize='large' ></props.Icon>} */}

                <h4 className="text-center text-uppercase text-white h2 fw-bold">
                  {" "}
                  {districtWiseServicePlusData.delivered}
                </h4>
                <div className="text-left ">
                  <p className="px-5">
                    {" "}
                    <span className="float-right text-white">
                      Application Delievered
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3  col-sm-8 ">
              <div className=" py-4 b-dbcard shadow-lg my-3 text-center number_pending rounded border_radius">
                {/* { <props.Icon fontSize='large' ></props.Icon>} */}

                <h4 className="text-center text-uppercase text-dark fw-bold h2">
                  {" "}
                  {districtWiseServicePlusData.pending}
                </h4>
                <div className="text-left ">
                  <p className="px-5">
                    {" "}
                    <span className="float-right text-dark">
                      Application Pending
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>

          <div className="mt-3">
            <div className="row text-center pl-4">
              <div className="col-lg-6 col-sm-12 p-3 total_registered  border rounded">
                <div className="p-4 b-dbcard ">
                  <Person className="text-large" fontSize="large"></Person>
                  <span className="fas fa-layer-group position-absolute" />
                  <div className>
                    <p className="text-left font-weight-bold fw-bold">
                      Total Verified Fish Farmer
                    </p>
                    <h3 className="text-left font-weight-bold">
                      {fishFarmerCount[0]?.districtCountOfFarmers}
                    </h3>
                    <div className="text-left"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="mt-5 " style={{}}>
          {districtFishFarmerGenderCount.length != 0 ? (
            <h3 className="mt-3 text-center">Gender</h3>
          ) : null}
          <div className="row">
            {districtFishFarmerGenderCount.length != 0 ? (
              districtFishFarmerGenderCount.map((farmerCount) => {
                return (
                  <div className="col-lg-6 col-sm-12 col-md-6 p-3  rounded_border">
                    <div className="p-4 border rounded ">
                      <div className="text-center">
                        <div className="row">
                          <div className="col-md-6">
                            <img
                              src={
                                farmerCount.gender === "F" ||
                                  farmerCount.gender === "FEMALE" ||
                                  farmerCount.gender === "Female"
                                  ? femaleIcon
                                  : maleIcon
                              }
                              height="100"
                              alt="Female"
                            ></img>
                          </div>
                          <div className="col-md-6">
                            <h2 className="text-left">{farmerCount.count}</h2>
                            <h4 className="text-secondary text-uppercase font-weight-bold">
                              {farmerCount.gender}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <h3 className="text-danger ">No Fish Farmers Verified So Far</h3>
            )}
          </div>
        </div>

        <hr />

        <div className="my-5">
          {districtFishFarmerGenderCount.length != 0 ? (
            <h3 className="text-center">Category Wise Registration</h3>
          ) : null}
          <div className="mt-3">
            <Pie data={data} options={options} style={{ height: "30vh" }}></Pie>
          </div>
        </div>
      </div>
    </div>
  )
}

DistrictHomeDashBoard.propTypes = propTypes
DistrictHomeDashBoard.defaultProps = defaultProps
// #endregion

export default DistrictHomeDashBoard
