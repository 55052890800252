import axios from "axios"

const instance = axios.create(
  {
    /*This is the base url that will be concatinated later and create in an inbuilt function of axios*/
    baseURL: window.location.protocol + "//" + window.location.host + "/fishFarmerPortal/public",
    //baseURL: "https://fisheries.meghalaya.gov.in/fishFarmerPortal/public",
    // baseURL: "http://10.179.2.92/fishFarmerPortal/public",
    // baseURL: "http://localhost:8080/public",
  }

  // Add a request interceptor
)

export default instance
