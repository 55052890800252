import React from 'react';
import styled from 'styled-components';
import '../district/css/table.css'
import '../district/css/table.css';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import axiosProtected from '../../../helper/axios_authenticated_urls';
import requests from '../../../helper/request';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { Error } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { doLogout } from '../../../auth/auth';
import ReLoginModal from '../../commons/ReLoginModal';


// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const columns = [
    { field: 'fishFarmerId', headerName: 'Fish Farmer Id', width: 200 },
    { field: 'pondId', headerName: 'PondId', width: 50, editable: false },
    { field: 'epicNumber', headerName: 'Epic Number', width: 90 },
    { field: 'name', headerName: 'Name', width: 150, editable: false },
    { field: 'gender', headerName: 'Gender', width: 150, editable: false },
    { field: 'age', headerName: 'Age', width: 110, editable: true },
    { field: 'pondLocationDistrict', headerName: 'Pond Location District', width: 150, editable: false },
    { field: 'pondLocationVillage', headerName: 'Pond Location Village', width: 150, editable: false },
    { field: 'pondLocationBlock', headerName: 'Pond Location Block', width: 150, editable: false },
    { field: 'totalLandArea', headerName: 'Total Land Area Available', width: 150, editable: false },
    { field: 'totalLandAreaForPond', headerName: 'Total Land Area For Pond', width: 150, editable: false },
    { field: 'dob', headerName: 'Date of Birth', width: 150, type: "date",valueGetter: ({ value }) => value && new Date(value),  },
    { field: 'farmerId', headerName: 'Farmer Id', width: 150, editable: false },
    { field: 'locality', headerName: 'Locality', width: 150, editable: false },
    { field: 'villageName', headerName: 'Village name', width: 150, editable: false },
    { field: 'blockName', headerName: 'Block Name', width: 150, editable: false },
    { field: 'farmerMotherOrHusbandName', headerName: 'Father/Mother/Husband Name', width: 150, editable: false },




];
const FishFarmerDetailsWithPond = () => {

    const [details, setdetails] = useState([]);
    const location = useLocation();

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    useEffect(() => {

        if (true) {

            let getFishFarmerDetailsByBlock = async () => {
                await axiosProtected.get(requests.fetchFarmersLinkedWithPondForVillage + "/" + location.state.id).then(
                    (res) => {
                        // console.log(location.state.id)
                        setdetails(res.data.data);
                        // console.log(res);
                    }
                )
                    .catch(error => {
                        if (error.response.status === 401 || error.response.status ===403) {
                            //  debugger
                            handleShow();

                        }
                    }

                    )

            }
            getFishFarmerDetailsByBlock();
        }



    }, []);
    return <>
        <div className='mt-5' style={{ height: "60vh", }}>
             <DataGrid
                getRowId={(row) => row.pondId}
                rows={details}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}

                disableSelectionOnClick
                editable={false}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            /> 
            {/* <div className="App">
      <table>
        <tr>
          <th>Name</th>
          <th>Age</th>
          <th>Gender</th>
          <th>Name</th>
          <th>Age</th>
          <th>Gender</th>
        </tr>
        {details.map((val, key) => {
          return (
            <tr key={key}>
              <td>{val.fishFarmerId}</td>
              <td>{val.pondId}</td>
              <td>{val.age}</td>
              <td>{val.gender}</td>
              <td>{val.pondId}</td>
              <td>{val.pondLocationDistrict}</td>
            </tr>
          )
        })}
      </table>
    </div> */}
            <ReLoginModal showModal={show}></ReLoginModal>
        </div>


    </>
}

FishFarmerDetailsWithPond.propTypes = propTypes;
FishFarmerDetailsWithPond.defaultProps = defaultProps;
// #endregion

export default FishFarmerDetailsWithPond;