import React from "react"
import PropTypes from "prop-types"
import "../commons/css/common.css"
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap"

import { NavLink, useNavigate, Navigate } from "react-router-dom"
import { useState, useEffect } from "react"

import LoginModal from "../forms/LoginModal"
import { isLoggedIn, doLogout } from "../../auth/auth"
import axios from "../../helper/axios"
import requests from "../../helper/request"
import {
  getUserName,
  getUserRole,
  getOfficeLocation,
} from "../../services/user-details"
import { getRoles } from "@testing-library/react"

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */
const CustomNavbar = () => {
  const navigate = useNavigate()

  const [showModal, setShow] = useState(false)
  const [loginState, setloginState] = useState(isLoggedIn() ? true : false)
  const [captchaShow, setcaptchaShow] = useState(false)
  const [img, setimg] = useState("")

  useEffect(() => {
    if (captchaShow == true) {
      let getCaptcha = async () => {
        let captcha = await axios.get(requests.fetchpk)
        // console.log("object")
        setcaptchaShow(false)
        // console.log(captcha.data.data);
        setimg(captcha.data.data.captchaString)
        sessionStorage.setItem("public_key", captcha.data.data.publicKey)
      }
      getCaptcha()
    }
  }, [captchaShow])

  // console.log(loginState+"LoginState")
  const handleClose = () => setShow(false)
  const handleShow = () => {
    setcaptchaShow(true)
    setShow(true)
  }

  const handleLogOut = () => {
    doLogout(() => {
      navigate("/")
    })
    setloginState(false)
  }

  return (
    <div className="globalnav-bg ">
      <div className="container">
        <Navbar collapseOnSelect expand="lg" variant="dark">
          <Container>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            {isLoggedIn() ? (
              <Navbar.Collapse id="responsive-navbar-nav text-white">
                <Nav className="me-auto text-white">
                  <NavLink
                    className="my-auto p-1 nav_link"
                    to="/dashboard/home"
                  >
                    DashBoard
                  </NavLink>
                  <NavLink
                    className="my-auto p-1 nav_link"
                    to="/dashboard/districtdetails"
                  >
                    District Farmer Details
                  </NavLink>
                  <NavLink
                    className="my-auto p-1 nav_link"
                    to="/dashboard/blockdetails"
                  >
                    Block Farmer Details
                  </NavLink>
                  <NavLink
                    className="my-auto p-1 nav_link"
                    to="/dashboard/ponddetails/blockwise"
                  >
                    Project Details
                  </NavLink>
                  {/* {getUserRole() === "SUPER_ADMIN" ? <NavLink to="/dashboard/auditTrail"  >AuditTrail</NavLink> : null} */}
                  {/* {getUserRole()==="SUPER_ADMIN" || getUserRole()==="ADMIN"? */}

                  {getUserRole() === "ADMIN" ? (
                    <>
                      <NavLink
                        className="my-auto p-1 nav_link"
                        to="/admin/contact"
                      >
                        Contact Us
                      </NavLink>
                      <NavLink className="my-auto p-1 nav_link" to="/admin/notification">
                        Notification
                      </NavLink>
                    </>

                  ) : null}

                  {getUserRole() === "SUPER_ADMIN" ? (
                    <NavDropdown title="Admin" id="basic-nav-dropdown">
                      <NavLink className="text-dark" to="/admin/contact">
                        Contact Us
                      </NavLink>
                      <br></br>
                      <hr></hr>
                      <NavLink className="text-dark" to="/admin/successStories">
                        Success Stories
                      </NavLink>
                      <br></br>
                      <hr></hr>
                      <NavLink className="text-dark" to="/admin/userdetails">
                        Users
                      </NavLink>
                      <br></br>
                      <hr></hr>
                      <NavLink className="text-dark" to="/admin/notification">
                        Notification
                      </NavLink>
                      <br></br>
                    </NavDropdown>
                  ) : null}
                </Nav>
                <Nav className="mx-3 text-white text-center text-uppercase my-2">
                  {" "}
                  {getUserName() === "superadmin" || getUserName() === "admin"
                    ? getUserName()
                    : getOfficeLocation()}
                </Nav>
                <Nav>
                  <button
                    className="btn btn-small btn-secondary text-uppercase"
                    onClick={handleLogOut}
                  >
                    Log Out
                  </button>
                  {/* <button className='btn btn-small btn-secondary' onClick={handleShow}>Login</button> */}
                </Nav>
              </Navbar.Collapse>
            ) : (
              <Navbar.Collapse id="responsive-navbar-nav text-white">
                <Nav className="me-auto text-white">
                  <NavLink className="my-auto p-1 nav_link" to="/">
                    Home
                  </NavLink>
                  <NavLink
                    className="my-auto p-1 nav_link"
                    to="public/dashboard"
                  >
                    DashBoard
                  </NavLink>
                  <NavLink className="my-auto p-1 nav_link" to="/services">
                    Services
                  </NavLink>
                  <NavLink
                    className="my-auto p-1 nav_link"
                    to="/successStories"
                  >
                    Success Stories
                  </NavLink>
                  <NavLink className="my-auto p-1 nav_link" to="/formsSection">
                    Forms
                  </NavLink>

                  {/* <NavLink to="/schemesMSAM" >MSAM2.0 Schemes</NavLink> */}

                  <NavDropdown
                    title="Fisheries Scenario"
                    id="basic-nav-dropdown"
                    className="nav_link"
                  >
                    <NavLink className="text-dark" to="/fisheriesSenario">
                      Fisheries Scenario
                    </NavLink>
                    <br></br>
                    <hr></hr>
                    <NavLink className="text-dark" to="/stateProfile">
                      State Profile
                    </NavLink>
                    <hr></hr>
                    <a
                      className="text-dark"
                      download={true}
                      href="documents/Case study on Fish Sanctuaries in East Garo HIlls.pdf"
                    >
                      Case Study
                    </a>
                  </NavDropdown>

                  <NavDropdown
                    title="Scheme "
                    id="basic-nav-dropdown"
                    className="nav_link"
                  >
                    <NavLink className="text-dark" to="/schemesMSAM">
                      MSAM2.0 Schemes
                    </NavLink>
                    <br></br>
                    <hr></hr>
                    <NavLink className="text-dark" to="/centralScheme">
                      Central Scheme
                    </NavLink>
                  </NavDropdown>

                  <NavLink className="my-auto p-1 nav_link" to="/about">
                    About Us
                  </NavLink>
                  <NavLink className="my-auto nav_link" to="/contact">
                    Contact Us
                  </NavLink>
                </Nav>
                <Nav>
                  <button
                    className="btn btn-small btn-secondary mt-1"
                    onClick={handleShow}
                  >
                    Login
                  </button>
                </Nav>
              </Navbar.Collapse>
            )}

            <LoginModal
              showModal={showModal}
              setShow={setShow}
              handleClose={handleClose}
              setloginState={setloginState}
              captchaImage={img}
              setimg={setimg}
            ></LoginModal>
          </Container>
        </Navbar>
      </div>
    </div>
  )
}

CustomNavbar.propTypes = propTypes
CustomNavbar.defaultProps = defaultProps
// #endregion

export default CustomNavbar
