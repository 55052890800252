import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';
import { useState } from 'react';

import img1 from '../../../src/images/NFFD2022.jpg'
// import img2 from '../../../src/images/AquaFest2022.png'
import img2 from '../../../src/images/RiverRanching.jpg'
import img3 from '../../../src/images/Trout2023.jpg'


// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const CustomCarousel = () => {
   
        const [index, setIndex] = useState(0);
      
        const handleSelect = (selectedIndex, e) => {
          setIndex(selectedIndex);
        };
    return <>
        <Carousel activeIndex={index} onSelect={handleSelect}>
          
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={img1}
                    alt="Second slide"
                    
                />

                <Carousel.Caption>
                    <h3>Fish Farmer Portal</h3>
                    <p>National Fish Farmer Day 2022 </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={img2}
                    alt="Third slide"
                    
                />

                <Carousel.Caption>
                    <h3>Fish Farmer Portal</h3>
                    <p> River Ranching</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={img3}
                    alt="First slide"
                    
                />
                <Carousel.Caption>
                    <h3>Fish Farmer Portal</h3>
                    <p>Trout Farm (Rainbow Trout)</p>
                </Carousel.Caption>
            </Carousel.Item>
            
        </Carousel>
    </>

}

CustomCarousel.propTypes = propTypes;
CustomCarousel.defaultProps = defaultProps;
// #endregion

export default CustomCarousel;