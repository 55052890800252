import React from 'react'

function CentralScheme() {
    return (
        <div className='container mt-3'>
            <h2> Central Schemes</h2>
            <p className='h4'>Pradhan Mantri Matsya Sampada Yojana:</p>
            <p>Scheme in continuation to the Blue Revolution launched in 2020-21 by the Ministry of Fisheries
                Animal Husbandry and Dairying. It is designed to address critical gaps in Fish Production and
                productivity, quality, technology, post harvest infrastructure and Management .
                It is an umbrella scheme of two Components that is The Central Sector Scheme and Centrally
                Sponsored Scheme. The Centrally sponsored is further divided into Beneficiary Oriented and
                Non Beneficiary Oriented (Govt Sector ) Programmes .</p>
            <ul>
                <li>
                    The Central Sector Scheme (CS)
                    <ol type='a'>
                        <li>The entire project/unit cost will be borne by the Central government(i.e. 100%
                            central funding).</li>
                        <li>Wherever direct beneficiary oriented i.e. individual/group activities are
                            undertaken by the entities of central government including National Fisheries
                            Development Board (NFDB), the central assistance will be upto 40% of the
                            unit/project cost for General category and 60% for SC/ST/Women category.</li>
                    </ol>
                </li>
                <li>Centrally Sponsored Scheme (CSS)</li>
            </ul>
            <p>
                For the Non-beneficiary orientated sub-components/activities under CSS component to be
                implemented by the States/UTs, the entire project/unit cost will be shared between Centre and
                State as detailed below:
                <ol type='a'>
                    <li>North Eastern &amp; Himalayan States : 90% Central share and 10% State share.</li>
                    <li>Other States : 60% Central share and 40% State share.</li>
                    <li>Union Territories (with legislature and without legislature): 100%Central share.</li>
                </ol>
            </p>
            <p>For the Beneficiary orientated i.e. individual/group activities sub components/activities
                under CSS component to be implemented by the States/UTs, the Government financial
                assistance of both Centre and State/UTs governments together will be limited to 40% of
                the project/unit cost for General category and 60% of the project/unit cost for
                SC/ST/Women. The Government financial assistance will in turn be shared between
                Centre and State/UTs in the ratio:The North Eastern &amp; the Himalayan States: 90%
                Central share.</p>
        </div>
    )
}

export default CentralScheme