import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import axiosProtected from '../../helper/axios_authenticated_urls';
import requests from '../../helper/request';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const SingleFarmerDetail = () => {

    const [farmer, setfarmer] = useState();

    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        let getFarmerDetails = async () => {

            await axiosProtected.post(requests.fetchFarmerById, {
                "farmerId": location.state.id
            }).then((response) => {
                setfarmer(response.data.data);
                // console.log(response.data.data)
            })
                .catch(error => {

                    // console.log(error);
                })
        }
        getFarmerDetails();

    }, [])

    const handleSubmit = (e)=>{
        e.preventDefault();
        
    }

    const handleBackPressed = (e)=>{
        e.preventDefault();
        navigate("/")
    }

    return (
        <>
            {
                farmer ? <div className='container my-3'>
                    <div className='card p-3'>
                        <form>
                            <div className="row">
                                <div className="form-group col-md-6 col-lg-6 col-6">
                                    <label htmlFor="fishFarmerId">Fish Farmer Id</label>
                                    <input type="text" className="form-control" disabled={true} value={farmer.fishFarmerId} id="fishFarmerId" placeholder="Fish Farmer Id"></input>
                                </div>
                                <div className="form-group col-md-6 col-lg-6 col-6">
                                    <label htmlFor="name">Name</label>
                                    <input type="text" className="form-control" disabled={true} value={farmer.name} id="name" placeholder="Name"></input>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6 col-lg-6 col-6">
                                    <label htmlFor="age">Age</label>
                                    <input type="text" className="form-control" id="age" disabled={true} value={farmer.age} placeholder="Age"></input>
                                </div>
                                <div className="form-group col-md-6 col-lg-6 col-6">
                                    <label htmlFor="dob">Date of Birth</label>
                                    <input type="text" className="form-control" id="dob" disabled={true} value={farmer.dob} placeholder="Date of Birth"></input>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6 col-lg-6 col-6">
                                    <label htmlFor="epicNumber">Epic Number</label>
                                    <input type="text" className="form-control" id="epicNumber" disabled={true} value={farmer.epicNumber} placeholder="Epic"></input>
                                </div>
                                <div className="form-group col-md-6 col-lg-6 col-6">
                                    <label htmlFor="gender">Gender</label>
                                    <input type="text" className="form-control" id="gender" disabled={true} value={farmer.gender} placeholder="Gender"></input>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6 col-lg-6 col-6">
                                    <label htmlFor="mobileNo">Mobile Number</label>
                                    <input type="text" className="form-control" id="mobileNo" disabled={true} value={farmer.mobileNo} placeholder="Mobile Number"></input>
                                </div>
                                <div className="form-group col-md-6 col-lg-6 col-6">
                                    <label htmlFor="registrationDate">Registration Date</label>
                                    <input type="text" className="form-control" id="registrationDate" disabled={true} value={farmer.registrationDate} placeholder="Registration Date"></input>
                                </div>
                            </div>
                            {/* <div className="form-group">
                    <label htmlFor="inputAddress">Address</label>
                    <input type="text" className="form-control" id="inputAddress" placeholder="1234 Main St"></input>
                </div>
                <div className="form-group">
                    <label htmlFor="inputAddress2">Address 2</label>
                    <input type="text" className="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor"></input>
                </div> */}
                            <hr></hr>
                            <p>Fish Farmer's Address</p>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="districtName">District</label>
                                    <input type="text" className="form-control" disabled={true} value={farmer.district.districtName} id="districtName"></input>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="blockName">Block</label>
                                    <input type="text" className="form-control" disabled={true} value={farmer.block.blockName} id="blockName"></input>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="villageName">Village</label>
                                        <input type="text" className="form-control" disabled={true} value={farmer.villageCode.villageName} id="villageName"></input>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="locality">Locality</label>
                                        <input type="text" className="form-control" disabled={true} value={farmer.locality} id="locality"></input>
                                    </div>
                                </div>


                                {/* <div className="form-group col-md-2 mt-3">
                        <label htmlFor="inputZip">Zip</label>
                        <input type="text" className="form-control" id="inputZip"></input>
                    </div> */}
                            </div>
                            <hr></hr>

                            {/* <p>Pond Address</p>
                    <div className="row mt-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="districtName">District</label>
                            <input type="text" className="form-control" id="districtName"></input>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="blockName">Block</label>
                            <input type="text" className="form-control" id="blockName"></input>
                        </div>
                        <div className="row mt-3">
                            <div className="form-group col-md-6">
                                <label htmlFor="villageName">Village</label>
                                <input type="text" className="form-control" id="villageName"></input>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="locality">Locality</label>
                                <input type="text" className="form-control" id="locality"></input>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="form-group col-md-6">
                                <label htmlFor="totalLandArea">Total Land Area</label>
                                <input type="text" className="form-control" id="totalLandArea"></input>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="totalLandAreaForPond">Total Land Area for Pond</label>
                                <input type="text" className="form-control" id="totalLandAreaForPond"></input>
                            </div>
                        </div> */}



                            {/* </div> */}
                            <hr></hr>

                            <button type="submit" className="btn btn-outline-primary mx-3" onClick={handleSubmit}>Save</button>
                            <button type="submit" className="btn btn-outline-primary" onClick={handleBackPressed}>Back</button>


                        </form>
                    </div>
                </div>
                    : null
            }





        </>
    );
}

SingleFarmerDetail.propTypes = propTypes;
SingleFarmerDetail.defaultProps = defaultProps;
// #endregion

export default SingleFarmerDetail;