import React from 'react'

function TermsAndCondition() {
  return (
    <div className='container'>
      <h4 className='text-center text-uppercase my-3'>Terms And Conditions</h4>
      <div className='"rounded-circle p-3 shadow'>
         <p>This website is designed, developed, maintained and updated by the Fisheries Department, Government of Meghalaya. Though all efforts have been made to ensure the accuracy and currency of the content on this website, the same should not be construed as a statement of law or used for any legal purposes. Incase of any ambiguity or doubts, users are advised to verify/check with the Department(s) and/or other source(s), and to obtain appropriate professional advice. Under no circumstances will this Department be liable for any expense, loss or damage including, without limitation, indirect or consequential loss or damage, or any expense, loss or damage whatsoever arising from use, or loss of use, of data, arising out of or in connection with the use of this website. These terms and conditions shall be governed by and construed in accordance with the Indian Laws. Any dispute arising under these terms and conditions shall be subject to the jurisdiction of the courts of India. The information posted on this website could include hypertext links or pointers to information created and maintained by non-Government/private organisations. Government of Meghalaya is providing these links and pointers solely for your information and convenience. When you select a link to an outside website, you are leaving the Government of Meghalaya website and are subject to the privacy and security policies of the owners/sponsors of the outside website. Government of Meghalaya, does not guarantee the availability of such linked pages at all times. Government of Meghalaya, cannot authorise the use of copyrighted materials contained in linked websites. Users are advised to request such authorisation from the owner of the linked website. Government of Meghalaya, does not guarantee that linked websites comply with Indian Government Web Guidelines.</p>
      </div>
     
      </div>
  )
}

export default TermsAndCondition