
const requests = {

    fetchDistrict: `/districts`,
    fetchFishFarmerCountByDistrict: `/farmerCountByDistrict`,
    fetchFishFarmerCountGender: `/farmerGenderCount`,
    fetchFishFarmerCountByLandOwnership: '/farmerCountByLandOwnership',
    fetchFishFarmersByDistrict: '/farmers/',
    fetchFishFarmerCountByCategory: '/farmerCategoryCount',
    fetchServicePlusDetails: '/services',
    fetchFishFarmerCountDistrictWise: '/district/farmerCount',
    fetchFishFarmerCountByCategoryDistrictWise: '/district/farmerCountByCategory',
    fetchFishFarmerCountByGenderDistrictWise: '/district/farmerCountByGender',
    fetchpk: '/getpk',
    fetchMonthWiseData: '/monthWiseCount',
    fetchBlocks: '/blocks',
    fetchFarmerBlockWise: '/farmerdetailsByBlock',
    fetchPondCountByDistrict: '/pondCountDistrictWise',
    fetchPondCountByBlock: '/pondCountBlockWise',
    fetchPondCountByVillage: '/pondCountVillageWise',
    fetchFarmersLinkedWithPondForVillage: '/ponds/fishFarmersDetailsVillageWise',
    fetchPondForAllVilalgesOfBlock: '/pondCount',
    fetchAllPondCountOfEachBlockInDistrict: '/pondsCount/block',
    fetchRegenerateToken: '/regenerateToken',
    fetchFarmerById: '/farmerById',
    fetchAuditTrail:'/auditTrial',
    fetchDistrictWiseServicePlusDetails:'/servicePlus/district/data',
    fetchContactDetails:'/contactUs',
    fetContactUsWithLevel:'/contactUsWithLevel',
    fetchSuccessStories:'/successStories',
    fetchAllUsers:'/user',
    fetchAllRoles:'/roles',
    fetchNotification:'/notification',
    changePassword:'/changePassword',
    fetchUserRoles:'/user/role'


}

export default requests;