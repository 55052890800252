import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import requests from "../../../helper/request"
import axiosProtected from "../../../helper/axios_authenticated_urls"
import { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import DistrictHomeDashBoard from "./DistrictHomeDashBoard"
import AdminDistrictSelectionComponent from "./AdminDistrictSelectionComponent"

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */
const HomeDashBoard = () => {
  const [showModal, setShow, districtcode, userRole, districtname] =
    useOutletContext()

  // console.log("District code Home= ", districtcode)
  // console.log(userRole);

  // This is the method ot get the details of the farmers for the table

  return (
    <div className="container">
      <div className="card mt-5">
        <div className="card-header ">
          <div className="text-center">
            <h4>Detail Summary of Fish Farmers</h4>
          </div>
        </div>
        <div className="card-body">
          {districtcode ? (
            <DistrictHomeDashBoard
              districtCode={districtcode}
              showModal={showModal}
              setShow={setShow}
              userRole={userRole}
              districtName={districtname}
            ></DistrictHomeDashBoard>
          ) : (
            <AdminDistrictSelectionComponent
              districtcode={districtcode}
              showModal={showModal}
              setShow={setShow}
              userRole={userRole}
            ></AdminDistrictSelectionComponent>
          )}
        </div>
      </div>
    </div>
  )

  //Do a check on the type of user if user is Admin or Super Admin give a drop down to select district and then show the dash borad if user is district user just show the district DashBoard
}

HomeDashBoard.propTypes = propTypes
HomeDashBoard.defaultProps = defaultProps
// #endregion

export default HomeDashBoard
