import React from 'react'
import { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import axios from '../../helper/axios_authenticated_urls'
import requests from '../../helper/request';
import { validateUsername } from '../../helper/Validation';

function UpdateUserModal({ show, handleClose, user, districts, role, setuser, update }) {
    const [error, seterror] = useState(false)


    const closeModal = () => {
        seterror(false)
        handleClose()
    }

    const handleMultiSelect = (event) => {
        // debugger
        // let value = 
        let value = Array.from(event.target.selectedOptions, option => option.value);
        let text = Array.from(event.target.selectedOptions, option => option.text);
        let rolesvalue = new Array();

        for (let i = 0; i < value.length; i++) {
            let r = {
                id: value[i],
                name: text[i]
            }
            rolesvalue.push(r)
        }

        // console.log(rolesvalue)


        setuser({
            ...user,
            roles: rolesvalue
        })

        // debugger
        // console.log(user)

    }

    function isDistrictUser(role) {
        return role.name == "DISTRICT_USERS";
    }

    const handleChange = (event, field) => {
        seterror(false)

        let data = event.target.value;
        // seterror(false);

        setuser({

            ...user,
            [field]: data
        })
        // console.log(user)


    }





    // To update the data 
    const handleUpdate = (event) => {
        // debugger

        if (error) {
            return
        }

        if (!validateUsername(user.userName)) {
            seterror(true)
            return
        }

        // debugger
        event.preventDefault();

        if (user.roles.find(isDistrictUser)) {
            let e = document.getElementById("districtId");
            let districtOffice = e.options[e.selectedIndex].text;
            user.officeLocation = districtOffice
        }


        if (!user.roles.find(isDistrictUser)) {
            user.districtId = ""
        }



        // console.log(user)

        if (update) {

            //handle UPdate
            axios.patch(requests.fetchAllUsers + "/" + user.userName, user)
                .then((response) => {
                    if (response.data.status === 200) {
                        closeModal()
                    }
                })
                .catch((error) => {

                    if (error.response) {
                        if (error.response.data.status === 409) {
                            seterror(true)
                        }
                    }
                })
        }
        else {
            // Create
            axios.post(requests.fetchAllUsers, user)
                .then((response) =>
                    // console.log(response.data.status)
                    seterror(false)
                )

                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.status === 409) {
                            seterror(true)
                        }
                    }
                })
        }
    }

    function getRolesIds() {
        let arr = new Array();

        if (user && user.roles) {
            for (let i = 0; i < user.roles.length; i++) {

                arr.push(user.roles[i].id)
            }
            return arr;
        }
        return;
    }

    return (<div className='text-center'>




        <Modal show={show} onHide={handleClose} centered backdrop="static">

            <Modal.Header >

                <Modal.Title className='text-center text-uppercase'>User Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error ? <div class="alert alert-danger  fade show" role="alert">
                    ! Please check all the fields {user ? validateUsername(user.userName) ? null : "User Name is not valid" : null}

                </div> : null}
                <div class="row p-2 mt-1">

                    <div class="col">
                        <label for="name"> Name</label>
                        <input type="text" class="form-control" placeholder="name" id='name'
                            aria-label="name" value={user ? user.name : null}
                            onChange={(event) => handleChange(event, 'name')} />
                    </div>
                    {/* {console.log(error)} */}

                    <div class="col">
                        <label for="userName"> User Name</label>
                        <input type="text" class="form-control"
                            placeholder="User Name" aria-label="User Name" id='userName'
                            value={user ? user.userName : null}
                            onChange={(event) => handleChange(event, 'userName')} />
                        {error && validateUsername(user.userName) ? <span className='text-danger'>user name is not valid</span> : null}
                    </div>
                </div>
                {/* {console.log(user.roles).filter()} */}

                <div class="row p-2 mt-1">

                    {/* <div class="col">
                    <label for ="officeLocation"> OfficeLocation</label>
                        <input type="text" class="form-control" disabled placeholder="office Location" id='officeLocation'
                        aria-label="officeLocation" value={user?user.officeLocation:null}
                        onChange={(event)=>handleChange(event,'officeLocation')} />
                    </div> */}

                    <div class="col">
                        <label for="districts"> Districts</label>

                        <select id="districtId" class="form-select"


                            value={user ? user.districtId : null} disabled={user ? user.roles ? user.roles.find(isDistrictUser) ? false : true : true : true}
                            onChange={(event) => handleChange(event, 'districtId')}>
                            <option value="" selected disabled>Choose...</option>

                            {districts.map((district) => {
                                return <option value={district.districtCode}>{district.districtName}</option>
                            })}

                        </select>
                    </div>
                </div>

                <div class="row p-2 mt-1">

                    <div class="col">
                        {update ? <div class="form-check form-switch">
                            <label className="form-check-label" for="flexSwitchCheckDefault">{user ? user.isActive ? "Active" : "Deactivated" : null}</label>
                            <input className="form-check-input" onClick={() => alert("Hello")}
                                checked={user ? user.isActive ? true : false : null}
                                type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                value={user ? user.isActive ? true : false : null} />

                        </div> : null}
                    </div>

                    <div class="col">
                        <label for="roles"> Roles</label>
                        { }

                        <select id="roles" class="form-select" multiple={true} value={getRolesIds()}
                            multiselect-search={true} onChange={(event) => handleMultiSelect(event)}>
                            <option disabled>Choose...</option>

                            {role.map((roles) => {
                                return <option value={roles.id}>{roles.name}</option>
                            })}

                        </select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>

                <Button onClick={closeModal}>Close</Button>
                <Button onClick={handleUpdate}>{update ? "Update" : "Add"}</Button>
            </Modal.Footer>
        </Modal>
    </div>)

}

export default UpdateUserModal