import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import axios from '../../../helper/axios';
import requests from '../../../helper/request';
import PondDetailsDistrictDashBoard from './PondDetailsDistrictDashBoard';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const AdminPondDetailsDashboard = (props) => {

    const [district, setdistrict] = useState([]);
    const [districtSelected, setdistrictSelected] = useState([]);

    const [showPondDetailsDistrictDashBoard, setshowPondDetailsDistrictDashBoard] = useState(false);
    const [districtName, setdistrictName] = useState("");

    // This the method to get the district
    useEffect(() => {

        let getDistrict = async () => {
            let districts = await axios.get(requests.fetchDistrict);
            setdistrict(districts.data.data);

        }

        getDistrict();
    }, []);

    return <>
        <div className='text-center mt-4'>
            <h1>Project Details</h1>
            <div className='text-center text-dark h5'> Please Select the District</div>
        </div>
        <div className='d-flex justify-content-center my-3 text-color-secondary'>

        <div className='p-3 card border-0 bg-light'>
            <div className='row'>
                <div className='col-md-4'>
                    <label htmlFor='dropdown-basic-button'>
                        District:
                    </label>
                </div>

                <div className='col-md-8'>
                    <select id="dropdown-basic-button" title="District" value={districtSelected}
                        onChange={(e) => {
                            setshowPondDetailsDistrictDashBoard(true);
                            setdistrictSelected(e.target.value);
                            const { options, selectedIndex } = e.target;
                            setdistrictName(e.target.options[selectedIndex].innerHTML)

                        }}>
                        <option value="" disabled>District</option>
                        {
                            district.map((dist) => {

                                return <option key={dist.districtCode} value={dist.districtCode}>{dist.districtName}</option>

                            })
                        }

                    </select>
                </div>
                </div>

            </div>


        </div>
        {
            showPondDetailsDistrictDashBoard ? <PondDetailsDistrictDashBoard districtCode={districtSelected} setShow={props.setShow} districtName={districtName}></PondDetailsDistrictDashBoard> : null
        }
    </>;
}

AdminPondDetailsDashboard.propTypes = propTypes;
AdminPondDetailsDashboard.defaultProps = defaultProps;
// #endregion

export default AdminPondDetailsDashboard;