import React from 'react'
import criticalinfrastructure1 from '../../../images/msam/criticalinfrastructure1.jpg'
import criticalinfrastructure2 from '../../../images/msam/criticalinfrastructure2.jpg'
import criticalinfrastructure3 from '../../../images/msam/criticalinfrastructure3.jpg'

function CriticalInfraStructure() {
    return (
        <>

            <div className='container py-5'>
                <div className='card border-0'>
                    <div className='card-header'>
                        <h2 className="text-center"> Mini Mission - II: Critical Infrastructure Development</h2>
                    </div>
                    <div className='card-body'>

                        <div className='row'>
                            <div className='col-md-4'>
                                <img src={criticalinfrastructure1} alt="" className='img-fluid' ></img>
                            </div>
                            <div className='col-md-8'>
                                <p className='text-justify'>It was observed through MSAM 1.0 that the FRP hatcheries had better performance and acceptability in the State for Fish seed Production than the conventional Eco Hatcheries. This inducted Technology from CIFA is well suited for the hilly terrain of the State as the land holdings are smaller.</p>
                            </div>

                            <div className='col-md-8'>
                                <p> MSAM 2.0 was conceptualised with the aim to address two major critical components-Seed and Feed.  This has led to success in terms of fish seed production with a production of 9.69 million fry in the year 2017-18. With the launch of MSAM in 2012, the Department of Fisheries has been able to complete 26 hatcheries, and 3 Fish feed mills, boosting the production of fish seed in the whole state of Meghalaya.</p>

                            </div>
                            <div className='col-md-4'>
                                <img src={criticalinfrastructure3} alt="" className='img-fluid' ></img>
                            </div>
                        </div>

                        <p className=""><a className='text-reset text-decoration-underline' href="http://msam.nic.in/docs/msam2_0.pdf" rel='noreferrer' target="_blank">For more details download mission document:</a></p>

                    </div>
                </div>









            </div>
        </>


    )
}

export default CriticalInfraStructure