import React from 'react'
import './css/spinner.css'
import loading from '../../../src/images/loading.gif';

function LoadingSpinner() {
  return (
    <div className='d-flex justify-content-center'>
        <div className="spinner-container align-items-center">
            <img src={loading} alt="Loading " height={250}></img>
           
          </div>
        </div>
   
 
        
     
  )
}

export default LoadingSpinner