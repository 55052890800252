import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import axios from '../../../helper/axios';
import requests from '../../../helper/request';
import BlockSelectionComponent from './BlockSelectionComponent';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const AdminBlockSelectionComponent = (props) => {
  const [showBlockDashBoard, setShowBlockDashBoard] = useState(false);
  const [district, setdistrict] = useState([]);
  const [districtSelected, setdistrictSelected] = useState([]);
  const [districtName, setdistrictName] = useState("");

  // This the method to get the district
  useEffect(() => {

    let getDistrict = async () => {
      let districts = await axios.get(requests.fetchDistrict);
      setdistrict(districts.data.data);

    }

    getDistrict();
  }, []);


  return <div>
    <h3 className='text-center mt-4 border bg-light'>Block Wise Detail View</h3>
    <p className='text-center h5 my-2'>Please Select District</p>
    <div className='d-flex justify-content-center my-3 text-color-secondary'>


      <div className='row'>

        <div className='col-md-4'>
          <label htmlFor='dropdown-basic-button'>
            District:
          </label>
        </div>
        <div className='col-md-8'>
          <select className='form-control' id="dropdown-basic-button" title="District" value={districtSelected}
            onChange={(e) => {
              setShowBlockDashBoard(true);
              setdistrictSelected(e.target.value);
              const { options, selectedIndex } = e.target;
              setdistrictName(e.target.options[selectedIndex].innerHTML)
              if (showBlockDashBoard) {
                  
              }

            }}>
            <option value="" disabled>District</option>
            {
              district.map((dist) => {

                return <option key={dist.districtCode} value={dist.districtCode}>{dist.districtName}</option>

              })
            }

          </select>
        </div>

      </div>

    </div>
    <hr className='my-3'></hr>
    {
      showBlockDashBoard ? <BlockSelectionComponent districtCode={districtSelected} setShow={props.setShow} districtName={districtName}></BlockSelectionComponent> : null
    }
  </div>;
}

AdminBlockSelectionComponent.propTypes = propTypes;
AdminBlockSelectionComponent.defaultProps = defaultProps;
// #endregion

export default AdminBlockSelectionComponent;