import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap'
import axios from '../../helper/axios_authenticated_urls'
import requests from '../../helper/request';
import { validatePassword } from '../../helper/Validation';
// import { convertLength } from '@mui/material/styles/cssUtils';

function ChangePassword({ showChangePasswordModal, handleChangePasswordClose, user }) {

    const [passwordDetails, setPasswordDetails] = useState({
        userName: user ? user.userName : null,
        existingPassword: "",
        newPassword: "",
        confirmPassword: ""
    })

    const [error, seterror] = useState([])
    const handleChange = (event, field) => {
        // debugger
        seterror(false)
        const value = event.target.value;


        setPasswordDetails(
            {
                ...passwordDetails,
                [field]: value
            }
        )
    }

    const handleConfirmPassword = (event, field) => {
        const val = event.target.value;
        if (val === passwordDetails.newPassword) {
            seterror(false)
            return;
        }
        else {
            seterror(true)
            return;
        }
    }

    const closeModal = () => {
        setPasswordDetails(
            {
                userName: "",
                existingPassword: "",
                newPassword: ""
            }
        )

        seterror(false)
        handleChangePasswordClose()
    }

    const handleChangePassword = () => {
        passwordDetails.userName = user.userName
        // debugger

        if (passwordDetails.existingPassword.trim() === "" || passwordDetails.userName.trim() === "" || passwordDetails.newPassword.trim() === "") {
            seterror(true)
            return
        }

        if (!validatePassword(passwordDetails.existingPassword)) {
            seterror(true);
            return;
        }

        delete passwordDetails.confirmPassword

        axios.post(requests.changePassword, passwordDetails)

            .then((response) => {
                if (response.status === 200) {
                    setPasswordDetails(
                        {
                            userName: "",
                            existingPassword: "",
                            newPassword: ""
                        }
                    )

                    handleChangePasswordClose(true)
                }
                else {
                    // console.log(response.status)
                    setPasswordDetails(
                        {
                            userName: "",
                            existingPassword: "",
                            newPassword: ""
                        }
                    )
                }

            }


            )
            .catch((error) => {

                if (error.response) {
                    //debugger
                    if (error.response.data.status === 401) {
                        // console.log(error.response.data.error.message)
                        seterror(error.response.data.error.message)
                    }
                }
            }
            )
    }
    // {error.length > 0 ? <DisplayError /> : null }
    // {error.length > 0 && <DisplayError />}

    // response?.data?.data?.map()
    // EnhancedComponent: <div>{isLoading && <>Loading...</></div>}

    return (
        <div>


            <Modal show={showChangePasswordModal} onHide={closeModal} centered backdrop="static">

                <Modal.Header >

                    <Modal.Title className='text-center text-uppercase'> Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {error.length > 0 ? <div class="alert alert-danger" role="alert">
                        {error}
                    </div> : null}
                    <form action="index.html" autocomplete="OFF">

                        <div className="inputb">
                            <label htmlFor="userName">userName</label>
                            <input className="forminput"
                                disabled
                                type="text"
                                id="userName"
                                placeholder='Enter your User Name:'
                                value={user ? user.userName : null}
                            // onChange={(event) => handleChange(event, 'userName')}
                            >
                            </input>

                        </div>

                        <div className="inputb">
                            <label htmlFor="password">Existing Password</label>
                            <input className="forminput hide-pwd"
                                type="password"
                                id="existingPassword"
                                name="existingPassword"
                                placeholder='Enter Old Password'
                                value={passwordDetails.existingPassword}
                                onChange={(event) => handleChange(event, 'existingPassword')}
                            ></input>

                        </div>
                        {/* {error && !validatePassword(passwordDetails.existingPassword)?<small className='text-danger'>ERRor</small>:null} */}
                        <div className="inputb">
                            <label htmlFor="password">New Password</label>
                            <input className="forminput hide-pwd"
                                type="password"
                                id="newPassword"
                                placeholder='Enter New Password'
                                value={passwordDetails.newPassword}
                                onChange={(event) => handleChange(event, 'newPassword')}
                            ></input>

                        </div>
                        {/* {error && !validatePassword(passwordDetails.existingPassword)?<small className='text-danger'>ERRor</small>:null} */}

                        <div className="inputb">
                            <label htmlFor="confirmpassword">Confirm Password</label>
                            <input className="forminput hide-pwd"
                                type="password"
                                id="confirmpassword"
                                placeholder='Confirm your New Password'
                                value={passwordDetails.confirmPassword}
                                onChange={(event) => handleConfirmPassword(event, 'newPassword')}
                            ></input>

                        </div>
                        {error && passwordDetails.newPassword !== passwordDetails.confirmPassword ? <small className='text-danger'>Error</small> : null}
                    </form>

                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={() => handleChangePassword()}>Change</Button>
                    <Button onClick={closeModal}>Close</Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ChangePassword