import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import "../commons/css/common.css"
import "../commons/css/footer.css"
import nicImage from "../../../src/images/NIC.png"
import { NavLink } from "react-router-dom"
import digitalIndia from "../../../src/images/digital-india.png"
import facebook from '../../images/facebook.svg'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */
const Footer = () => {
    return (
        <div className="my-bg-primary text-white pt-5" >
            <footer className="px-5 container-fluid">
                <div className="d-flex justify-content-between">
                    <div className="row footer-nav">
                        <p className="row">Quick Links —</p>
                        <div className="col">
                            <ul className="list">
                                <li><NavLink to="about">About Us</NavLink></li>
                                <li><NavLink to="contact">Contact Us</NavLink></li>

                            </ul>
                        </div>
                        <div className="col">
                            <ul className="list">
                                <li><NavLink to="privacyPolicy">Privacy Policy</NavLink></li>
                                <li><NavLink to="termsandcondition">Terms &amp; Condition</NavLink></li>

                            </ul>
                        </div>
                    </div>
                    <div className="row footer-social ">
                        <div className="d-inline-block align-self-center">
                            <p className="bg-light"><img src={nicImage} alt="NIC logo" /></p>
                            <p className="bg-light mb-0"><img src={digitalIndia} alt="digital india logo" /></p>
                        </div>
                    </div>
                    <div className="row footer-nav">
                        <p className="row">Connect with Us —</p>
                        <div className="col">
                            <ul className="list-unstyled">
                                <li><a href="https://www.facebook.com/photo?fbid=636783391796005&set=pb.100063930492878.-2207520000" target="_blank" rel="noreferrer"><i class="facebook"></i>Facebook</a></li>
                                <li><a href="https://twitter.com/MegFisheryDept/status/1651543628626665472?s=20" target="_blank" rel="noreferrer"><i class="twitter"></i>Twitter</a></li>
                                <li><a href="https://www.instagram.com/p/CriXf7kriNE/?utm_source=ig_web_copy_link" target="_blank" rel="noreferrer"><i class="instagram"></i>Instagram</a></li>
                            </ul>
                        </div>
                        <div className="col">
                            <ul className="list-unstyled">
                                <li><a href="https://www.linkedin.com/in/department-of-fisheries-government-of-meghalaya-985a88282" target="_blank" rel="noreferrer"><i class="linkedin"></i>Linkedin</a></li>
                                <li><a href="https://youtube.com/@FisheriesDepartmentMeghalaya?si=oGZBGLhwzP3FwKdB" target="_blank" rel="noreferrer"><i class="youtube"></i>Youtube</a></li>
                                <li><a href="https://www.kooapp.com/profile/fisheriesgovernmentmeghalaya" target="_blank" rel="noreferrer"><i class="kooapp"></i>Koo App</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-5">
                    <div className="fst-italic fw-bold">Number of Visitors: 447</div>
                    Contents owned, maintained and updated by
                    <a
                        className="text-light font-weight-bold"
                        href="https://www.fisheries.meghalaya.gov.in"
                    >
                        Fisheries Department, Govt. of Meghalaya
                    </a>
                    <br />
                    Site designed, developed and hosted by
                    <a
                        className="text-light font-weight-bold"
                        href="https://www.nic.in/"
                    >
                        National Informatics Centre
                    </a>
                </div>
            </footer>
        </div>
    )
}

Footer.propTypes = propTypes
Footer.defaultProps = defaultProps
// #endregion

export default Footer
