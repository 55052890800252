
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
// import './assets/css/style.css'

import { BrowserRouter } from 'react-router-dom';

import Header from './components/commons/Header';
import CustomNavbar from './components/commons/CustomNavbar';


import Footer from './components/commons/Footer';
import AppRoutes from './helper/AppRoutes';
import axios from 'axios';
import { useEffect } from 'react';
import { isLoggedIn } from './auth/auth';
import { verifyJwtToken } from './helper/jwtverification';



function App() {

  useEffect(() => {

    const interval = setInterval(() => {
      const verifyLogeedInd = async () => {
        if (isLoggedIn()) {

          verifyJwtToken()
          //  console.log("data"); 
          // localStorage.removeItem("data");

        }


      }

      verifyLogeedInd();
    }, 120000);

    return () => clearInterval(interval);


    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])


  return (


    <>
      <BrowserRouter>
        <Header></Header>
        {/* {callApi()} */}

        <CustomNavbar></CustomNavbar>
        <div className='mx-auto' style={{ minHeight: "50vh" }}>
          <AppRoutes></AppRoutes>

        </div>
        <Footer></Footer>

      </BrowserRouter>
    </>
  );
}

export default App;

