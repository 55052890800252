import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react';
import axios from '../../helper/axios_authenticated_urls'
import requests from '../../helper/request';
import { validateEmail } from '../../helper/Validation';
import { Error } from '@mui/icons-material';
import ReLoginModal from '../commons/ReLoginModal';



function ContactUsInput() {

  const [show, setShowRelogin] = useState(false);
  const handleShowReloginModal = () => setShowRelogin(true);
  const location = useLocation();
  const [contactDetails, setcontactDetails] = useState({
    "id": location.state ? location.state.contactDetail.id : "",
    "name": location.state ? location.state.contactDetail.name : "",
    "designation": location.state ? location.state.contactDetail.designation : "",
    "email": location.state ? location.state.contactDetail.email ? location.state.contactDetail.email.replace("[at]", "@").replace("[dot]", ".") : "" : "",
    "level": "",
    "designationId": location.state ? location.state.contactDetail.designationId : ""
  })
  const [error, seterror] = useState(false);
  const navigate = useNavigate();

  const createContact = (e) => {

    e.preventDefault();

    if (contactDetails.name.trim() === "" || contactDetails.designation.trim() === "") {
      seterror(true);
      return;
    }

    if (!validateEmail(contactDetails.email)) {

      if (contactDetails.email.trim() !== "") {
        seterror(true);
        return;
      }

    }

    if (contactDetails.designationId == 8) {
      let distInput = document.getElementById("district");
      if (distInput.value == "") {
        seterror(true)
        return;
      }
    }

    if (contactDetails.level.trim() == "") {
      seterror(true);
      return
    }
    if (contactDetails.level === "SECRETARIAT LEVEL" || contactDetails.level === "DIRECTORATE LEVEL") {

      axios.post(requests.fetchContactDetails, contactDetails)
        .then((response) => {

          if (response.data.status === 200) {
            navigate("/admin/contact")
          }
          else if (response.data.status === 401 || response.data.status === 403) {
            handleShowReloginModal();
          }
        })
    }
  }

  const updateContact = (e) => {


    e.preventDefault();

    if (contactDetails.name.trim() === "" || contactDetails.designation.trim() === "") {
      seterror(true);

      return;
    }

    if (contactDetails.level.trim() === "") {
      seterror(true);
      return
    }

    if (contactDetails.designationId == 8) {
      let distInput = document.getElementById("district");
      if (distInput.value == "") {
        seterror(true)
        return;
      }
    }

    if (!validateEmail(contactDetails.email)) {
      if (contactDetails.email != "") {
        seterror(true);
        return;
      }
      // alert("NOt valid email");

    }

    if (contactDetails.level === "SECRETARIAT LEVEL" || contactDetails.level === "DIRECTORATE LEVEL") {

      axios.patch(requests.fetchContactDetails, contactDetails)
        .then((response) => {
          if (response.data.status === 200) {
            navigate("/admin/contact")
          }
          else if (response.data.status === 401 || response.data.status === 403) {
            handleShowReloginModal();
          }
        })
    }

  }

  const handleInput = (event, field) => {
    seterror(false)
    let data = event.target.value;
    let districtSec = document.getElementById("districtSection");


    if (field == "designationId") {
      if (data == 0) {
        seterror(true);
        return;
      }


      let index = event.nativeEvent.target.selectedIndex;
      let val = event.nativeEvent.target[index].text

      if (data == 8) {
        districtSec.hidden = false;

      }
      else {
        districtSec.hidden = true;
        let distInput = document.getElementById("district");
        distInput.value = ""
      }


      setcontactDetails({
        ...contactDetails,
        designation: val,
        designationId: data
      })
    }

    else {
      if (contactDetails.designationId != 8) {
        districtSec.hidden = true;
      }


      setcontactDetails({
        ...contactDetails,
        [field]: data

      })

    }

  }



  const handleDistrict = (event) => {
    let data = event.target.value;
    // document.getElementById("dis")
    // debugger


    if (data != null && data.length == 0) {
      let designation = contactDetails.designation;
      let desg = designation + data;

      setcontactDetails({
        ...contactDetails,
        designation: desg
      })

    }

    else {
      let designation = "Superintendent of Fisheries "
      setcontactDetails({
        ...contactDetails,
        designation: designation + data
      })
    }
  }

  return (

    <div className='container my-3'>
      {/* {
        error ? <div class="alert alert-warning alert-dismissible fade show" role="alert">
          <div className='text-danger text-center  text-uppercase' > Please enter all the details</div>

        </div> : null
      } */}
      <div className='card border-1'>
        <div className='card-header'>
          <div className='card-title text-center text-uppercase fw-bold'> Update Contact Details</div>

        </div>
        <div className='card-body'>
          <form>
            <div class="row">
              <div class="form-group col-md-6 my-2">
                <label for="contactName">Name</label>
                <input type="text" class="form-control"
                  id="name" placeholder="Name"
                  required value={contactDetails.name}
                  onChange={(event) => handleInput(event, "name")} />
                {error === true && contactDetails.name === "" ? <><small><Error small className='text-danger'></Error>  Please Fill in the name</small></> : null}
              </div>
              <div class="form-group col-md-6 my-2">
                <label for="designation">Designation</label>
                {/* <input type="text" class="form-control"
                  id="designation" placeholder="Designation"
                  value={contactDetails.designation}
                  required={true}
                  onChange={(event) => handleInput(event, "designation")} /> */}
                <select id="desgination" class="form-control" required
                  value={contactDetails.designationId} onChange={(event) => handleInput(event, "designationId")}>
                  <option value={0} selected="selected">Select the Designation</option>
                  <option value={1}>Secretary</option>
                  <option value={2}>Joint Secretary</option>
                  <option value={3}>Deputy Secretary</option>
                  <option value={4}>Director of Fisheries</option>
                  <option value={5}>Joint Director of Fisheries</option>
                  <option value={5.1}>Joint Director of Fisheries(Garo Hills Region)</option>
                  <option value={6}>Deputy Director of Fisheries</option>
                  <option value={6.1}>Deputy Director of Fisheries(Garo Hills Region)</option>
                  <option value={7}>Assistant Director of Fisheries</option>
                  <option value={7.1}>Assistant Director of Fisheries(Garo Hills Region)</option>
                  <option value={7.2}>Assistant Director of Fisheries(i/c Principal MSFR&TI)</option>
                  <option value={8}>Principal MSFR&TI</option>
                  <option value={9}>Superintendent of Fisheries</option>
                </select>
                {error === true && (contactDetails.designation === "" || contactDetails.designation === "Select the Designation") ? <><Error className='text-danger text-small'></Error>
                  <small className='text-danger'>Please Fill in the designation</small></> : null}
              </div>
              <div class="form-group col-md-6 my-2">
                <label for="email">Email</label>
                <input type="email" class="form-control"
                  required={true}
                  id="email" placeholder="Email"
                  value={contactDetails.email} onChange={(event) => handleInput(event, "email")} />
                {error === true && (contactDetails.email === "" || !validateEmail(contactDetails.email)) ? <><Error className='text-danger text-small'></Error>
                  <small className='text-danger'>Please Fill in the correct Email</small></> : null}
              </div>

              <div class="form-group col-md-6 my-2" id='districtSection' hidden={contactDetails.designationId == 8 ? false : true}>
                <label for="district">District</label>
                <input type="district" class="form-control"
                  // required={true}
                  // value={contactDetails.designationId==8?contactDetails.designation:""}
                  id="district" placeholder="district"
                  onChange={(event) => handleDistrict(event)} />
                {error === true && (document.getElementById("district").value === "") ? <><Error className='text-danger text-small'></Error>
                  <small className='text-danger'>Please Fill in the District </small></> : null}
              </div>
              <div class="form-group col-md-6 myh-2">
                <label for="inputState">Level</label>
                <select id="level" class="form-control"
                  value={contactDetails.level} onChange={(event) => handleInput(event, "level")}>
                  <option selected>Choose</option>
                  <option>SECRETARIAT LEVEL</option>
                  <option>DIRECTORATE LEVEL</option>
                </select>
                {error === true && (contactDetails.level === "") ? <><Error className='text-danger text-small'></Error>
                  <small className='text-danger'>Please Fill in the District </small></> : null}
              </div>

            </div>
            {location.state ?
              <button class="btn btn-primary" onClick={(e) => updateContact(e)} >Update</button>

              : <button className='btn btn-primary' onClick={(e) => createContact(e)}>Create</button>}

          </form>
        </div>
      </div>
      <ReLoginModal></ReLoginModal>
    </div>
  )
}

export default ContactUsInput