import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import axios from '../../../helper/axios';
import { useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import CustomFarmerDataTable from './CustomFarmerDistrictDataTable';
import requests from '../../../helper/request';
import LoadingSpinner from '../../commons/LoadingSpinner';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const FarmerDetails = () => {

    const [showModal, setShow, districtCode, userRole] = useOutletContext();
    const [dataTable, showDataTable] = useState(false);

    const [district, setdistrict] = useState([]);
    const [districtSelected, setdistrictSelected] = useState([]);
    const [districtName, setdistrictName] = useState("");

    // This the method to get the district
    useEffect(() => {

        let getDistrict = async () => {
            let districts = await axios.get(requests.fetchDistrict);
            setdistrict(districts.data.data);


        }

        getDistrict();
    }, []);

    return (
        districtCode ?

            <CustomFarmerDataTable districtCode={districtCode} showDataTable={dataTable} setShowDataTable = {showDataTable}></CustomFarmerDataTable> :

            <>
                <div className='text-center my-3'>
                    <h3 className='bg-light border p-3'>Detailed View for  {districtName ? districtName + " district" : null}</h3>
                </div>
                <p className='text-center h5'>Please Select the district</p>
                <div className='d-flex justify-content-center mt-3  text-color-secondary'>
                    <div className='row'>

                        <div className='col-md-4'>
                            <label htmlFor='dropdown-basic-button'>
                                District:
                            </label>

                        </div>
                        <div className='col-md-8'>
                            <select className='form-control' id="dropdown-basic-button" title="District" value={districtSelected}
                                onChange={(e) => {
                                    
                                    showDataTable(true);
                                    setdistrictSelected(e.target.value);
                                    const { options, selectedIndex } = e.target;
                                    setdistrictName(e.target.options[selectedIndex].innerHTML)

                                }}>
                                <option value="" disabled>District</option>
                                {
                                    district.map((dist) => {

                                        return <option key={dist.districtCode} value={dist.districtCode}>{dist.districtName}</option>

                                    })
                                }

                            </select>
                        </div>
                    </div>
                </div>

                <hr className='my-3'></hr>
                
              
                { 

                    dataTable &&
                    <CustomFarmerDataTable districtCode={districtSelected} districtName={districtName} showDataTable={dataTable} />
                }
            </>
    )

}

FarmerDetails.propTypes = propTypes;
FarmerDetails.defaultProps = defaultProps;
// #endregion

export default FarmerDetails;