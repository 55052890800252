import React from 'react'
import capacityBuilding1 from '../../../images/msam/Capacitybuilding1.jpg'
import capacityBuilding2 from '../../../images/msam/CapacityBuilding2.jpg'

function CapacityBuilding() {
  return (
    <> 
   

    <div className='container py-5'>
      <div className='card border-0'>
        <div className='card-header'>
          <h2 className="text-center"> Mini Mission - IV: Capacity Building and Human Resource Development</h2>
        </div>
        <div className='card-body'>

        <div className='row'>
          <div className='col-md-3'>
          <img src={capacityBuilding1} alt="" className='img-fluid' ></img>
          </div>
          <div className='col-md-9'>
            <p className='text-justify'>The core foundation upon which the success of Fisheries in Meghalaya is realized is through capacity building and human resources. Capacity Development (CD) as defined by OECD is the “process whereby people, organizations and society as a whole unleash, strengthen, create, adapt and maintain capacity over time”. The Mission envisages to develop and strengthen the core of human and institutional resources of the state by going beyond the Government sector, i.e, commercial enterprises and non-Governmental organizations. In short, it will focuses on achieving the objectives of the Mission by delivering the services atthe Individual and Institutional levels. The Department of Fisheries undertakes capacity building for Aquaculture and allied activities which include training on scientific know-how of Fish culture, preparation of training materials, raising  awareness through workshops and training, efficient management of hatcheries, fish seed production, ornamental fishery, fish production in ponds and tanks, disease management, fish feed production, processing and marketing of fish, etc.</p>
          </div>
        
          <div className='col-md-9'>
          <p>There are two main components of this Mini Mission. They are –</p>
          <ol>
		<li>Building the capacities of the stakeholders, and </li>
		<li>Creating/strengthening the training infrastructure</li>
		</ol>
          
          </div>
          <div className='col-md-3'>
          <img src={capacityBuilding2} alt="" className='img-fluid' ></img>
          </div>
        </div>

        <p className=""><a className='text-reset text-decoration-underline' href="http://msam.nic.in/docs/msam2_0.pdf" rel='noreferrer' target="_blank">For more details download mission document:</a></p>
  
        </div>
      </div>

        
        

{/* <p ><img src={paddy1} alt="" width="360" height="270" align="right"></img><span className="style7">Through the Meghalaya State Aquaculture Mission 1.0 (MSAM) the state has brought in an additional water area of 1808.80 hectaresbetween2012-2017. The state through creation of individual ponds has uplifted the livelihoods of the rural people and provided employment to the local youth. The state still needs to develop more water area to meet the increasing demand of fish. Under MSAM 2.0 it is sought to assist a minimum water area starting from 0.05 Hectare to a maximum water area of 1.0 Hectare to encourage more participation of the beneficiaries under the Mission. Construction of new ponds will be implemented mostly in the individual lands, community lands (on lease) etc. Meghalaya has vast land areas under the control of the village communities. The people of the state exhibit strong community engagement in fish capture in the rivers. If this takes place in the culture, rather than capture from the rivers, it will protect the indigenous fish species from extinction. The state has many untapped resources from the reservoirs and lakes. The state also has gained momentum from the practice of paddy-cum-fish culture. The water used for agriculture is also utilized for fish culture.</span></p> */}
{/* 
<!--<p className="imgclassName_l"><img src="images/ipwgh.jpg" alt="area_expn2" width="360" height="203" align="left" /></p>--> */}
  {/* <p className="imgclass_l"><img src={paddy2} alt="area_expn2" width="200" height="100" align="left"></img> Production of fish in rice fields is almost as old as the practice of paddy cultivation itself. As water stagnates in the fields for paddy culture, fishes which naturally occur in the nearby tanks and pools enter the paddy fields and grow there along with paddy until harvest time. This type of fish production dates back from very early days, even though it was based on capture rather than culture. Rice and fish exist at some level together for food particularly in South East Asian countries and there are age old systems prevalent for their combined cultivation in India.</p> */}
 

  
			
</div>
    </>
   
  )
}

export default CapacityBuilding