// As methods for login, login and other login related login 

export const doLogin = (data, next) => {

    if (data !== undefined) {
        localStorage.setItem("data", JSON.stringify(data));
        // sessionStorage.setItem("data", JSON.stringify(data));
        next();
    }


}

export const isLoggedIn = () => {
    let data = localStorage.getItem("data");
    //    let data = sessionStorage.getItem("data", JSON.stringify(data));
    // console.log("From is logged in" + "data")

    if (data != null) {

        // console.log(data);
        return true;
    }
    else
        return false;

}

export const doLogout = (next) => {
    localStorage.removeItem("data");
    next();
}



export const getToken = () => {

    if (isLoggedIn && localStorage.getItem("data") != null) {
        return JSON.parse(localStorage.getItem("data")).token;
    }
    else {
        return false;
    }
}