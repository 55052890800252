import { isLoggedIn } from "../auth/auth";
import jwt_decode from "jwt-decode";
import { doLogout
 } from "../auth/auth";
import { useNavigate } from "react-router-dom";

export const verifyJwtToken = (navigate) => {

    

    if (isLoggedIn()) {
        let token = JSON.parse(localStorage.getItem("data")).token;
        var decoded = jwt_decode(token);
        // console.log("first")
    
    if(new Date(Date.now()) > new Date (decoded.exp*1000)){  
             
        doLogout(
            ()=>{
                navigate("/")
            }
        );
        }

    }
    else {
        return false;
    }
}