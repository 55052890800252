import React from 'react'
import BeatLoader from "react-spinners/BeatLoader";

function BoatLoaderLazy() {
  return (
    <BeatLoader className='p-5'
              cssOverride={{
                display: "block",
                margin: "auto",
                borderColor: "red",
                textAlign: "center"}}
              size={50}>
            </BeatLoader>
  )
}

export default BoatLoaderLazy