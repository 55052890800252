import React from 'react'
import { useEffect, useState } from 'react'
import requests from '../../../helper/request'
import { useOutletContext } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axiosProtected from '../../../helper/axios_authenticated_urls';
// import axios from '../../../helper/axios';
import { getUserRole } from '../../../services/user-details';

function AuditTrail() {

  const [details, setdetails] = useState([]);
  const [showModal, setShow, districtCode, userRole] = useOutletContext();

  const columns = [
    // {
    //   field: 'Edit',
    //   headerName: 'Select',
    //   width: 80,
    //   renderCell: (params) =>
    //     <button className='btn-outline-primary btn btn-sm text-sm'
    //       onClick={() => { navigate("/farmer", { state: { id: params.row.fishFarmerId } }) }}
    //     >
    //       <Edit style={{ fontSize: "15px", margin: "2px" }} className></Edit>
    //       Edit
    //     </button>
    //   ,
    //   disableClickEventBubbling: true,
    // },
    { field: 'slNo', headerName: 'Serial Number', width: 90 },
    { field: 'remoteAddress', headerName: 'Remote Address', width: 150 },
    { field: 'userName', headerName: 'UserName', width: 150, editable: false },
    { field: 'url', headerName: 'Url Accessed', width: 150, editable: false },
    { field: 'simpleDate', headerName: 'Time and Date of Accessed', type: 'number', width: 110, editable: true },
    { field: 'method', headerName: 'Http Method', width: 150, type: "date" },
    { field: 'farmerId', headerName: 'Farmer Id', width: 150, editable: false },
    { field: 'locality', headerName: 'Locality', width: 150, editable: false },
    { field: 'villageName', headerName: 'Village name', width: 150, editable: false },
    { field: 'blockName', headerName: 'Block Name', width: 150, editable: false },
    { field: 'farmerMotherOrHusbandName', headerName: 'Father/Mother/Husband Name', width: 150, editable: false }
  ]
  useEffect(() => {
    //  console.log (getUserRole());

    let getFarmersDistrictWise = async () => {
      await axiosProtected.get(requests.fetchAuditTrail).then(
        (res) => {
          // console.log(res.data.data);
          setdetails(res.data.data);
          // console.log(details);
        }

      )
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500 || error.response.status === 403) {
            // console.log("object")
            setShow(true)


          }
        }

        )

    }

    getFarmersDistrictWise();
    // fetchData();

  }, [])



  return (
    <>
      {/* <div className='my-5' style={{ height: "60vh", width: '100%' }}>
      <DataGrid
        getRowId={(row) => row.slNo}
        rows={details}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        editable={false}
        components={{ Toolbar: GridToolbar }}

        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },

        }}
      />
    </div> */}

      <div className="App">
        <table>
          <tr>
            <th>Serial No</th>
            <th>Remote Address</th>
            <th>User Name</th>
            <th>Url</th>
            <th>Simple Date</th>
            <th>Method</th>
            {/* <th>Block Name</th>
          <th>Locality</th> */}

          </tr>
          {details.map((val, key) => {
            return (
              <tr key={key}>
                <td>{val.slNo}</td>
                <td>{val.remoteAddress}</td>
                <td>{val.userName}</td>
                <td>{val.url}</td>
                <td>{val.simpleDate}</td>
                <td>{val.method}</td>
                {/* <td>{val.blockName}</td>
              
              <td>{val.locality}</td> */}
              </tr>
            )
          })}
        </table>
      </div>


    </>
  )
}

export default AuditTrail