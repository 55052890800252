import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Line } from "react-chartjs-2";
import Chart from 'chart.js/auto'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const LineChart = ({ chartData }) => {
    return <>
        <p className='text-center'>
            Farmer Registered District Wise per Month
        </p>
        <Line data={chartData} ></Line>
    </>;
}

LineChart.propTypes = propTypes;
LineChart.defaultProps = defaultProps;
// #endregion

export default LineChart;