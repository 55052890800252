import { Delete, Edit } from '@mui/icons-material';
import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../helper/axios'
import axiosAuth from '../../helper/axios_authenticated_urls'
import requests from '../../helper/request';
import ReLoginModal from '../commons/ReLoginModal';

function ContactUsAdmin() {

    const [show, setShowRelogin] = useState(false);
    const handleShowReloginModal = () => setShowRelogin(true);
    const [contactDetailsSecreterait, setcontactDetailsSecreterait] = useState([]);
    const [contactDetailsDirectorateLevel, setcontactDirectorateLevel] = useState([]);

    const navigate = useNavigate();

    const handleDelete = (id, level) => {

        axiosAuth.delete(requests.fetchContactDetails + "/" + id)
            .then((response) => {
                if (response.data.status === 200) {
                    axios.get(requests.fetContactUsWithLevel + "/" + level)
                        .then((response) => {

                            level === "SECRETARIAT LEVEL" ? setcontactDetailsSecreterait(response.data.data) : setcontactDirectorateLevel(response.data.data)

                        })
                }

                else if (response.data.status === 401 || response.data.status === 403) {
                    handleShowReloginModal();
                }

                else {
                }
            })

    }


    useEffect(() => {

        let getContactDetailsSec = async () => {

            let contactDetailsSec = await axios.get(requests.fetContactUsWithLevel + "/SECRETARIAT LEVEL");
            setcontactDetailsSecreterait(contactDetailsSec.data.data);
        }

        getContactDetailsSec();

    }, []);

    useEffect(() => {

        let getContactDetailsDirect = async () => {

            let contactDetailsDirec = await axios.get(requests.fetContactUsWithLevel + "/DIRECTORATE LEVEL");
            setcontactDirectorateLevel(contactDetailsDirec.data.data);
        }

        getContactDetailsDirect();

    }, []);

    return (

        <div className='container'>
            <div className='h4 text-center text-uppercase my-3'> Add/Update Contact Details
                <div className='btn btn-secondary float-end' onClick={() => { navigate("update") }}> Add New</div></div>

            <div className='card border-1 mt-2'>
                <div className='card-header'>
                    <div className='card-title text-center text-uppercase fw-bold'> SECRETARIAT LEVEL</div>

                </div>
            </div>

            <div className='card-body table-responsive'>
                <table className='table table-bordered table' >
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>E-mail</th>
                            <th className='text-center'>Action</th></tr>
                        {contactDetailsSecreterait ? contactDetailsSecreterait.map((contactDetail, key) => {

                            return (<>
                                <tr>
                                    <td>{contactDetail.name}</td>
                                    <td>{contactDetail.designation}</td>
                                    <td>{contactDetail.email}</td>
                                    <td className='d-flex justify-content-around'>
                                        <button className='btn btn-sm btn-primary'
                                            onClick={() => navigate("/admin/contact/update", { state: { contactDetail: contactDetail } })}><Edit></Edit>Update</button>

                                        <button className='btn btn-sm btn-danger'
                                            onClick={() => handleDelete(contactDetail.id, contactDetail.level)}> <Delete></Delete> Delete</button></td>
                                </tr>
                            </>)
                        }) : null}
                    </tbody>
                </table>
            </div>


            <div className='card border-1'>
                <div className='card-header'>
                    <div className='card-title text-center text-uppercase fw-bold'> DIRECTORATE LEVEL</div>

                </div>
            </div>
            <div className='card-body table-responsive'>
                <table className='table table-bordered table' >
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>E-mail</th>
                            <th className='text-center'>Action</th></tr>
                        {contactDetailsDirectorateLevel ? contactDetailsDirectorateLevel.map((contactDetail, key) => {

                            return (<><tr>
                                <td>{contactDetail.name}</td>
                                <td>{contactDetail.designation}</td>
                                <td>{contactDetail.email}</td>
                                <td className='d-flex justify-content-around'>
                                    <button className='btn btn-sm btn-primary'
                                        onClick={() => navigate("/admin/contact/update", { state: { contactDetail: contactDetail } })}> <Edit></Edit>Update</button>
                                    <button className='btn btn-sm btn-danger'
                                        onClick={() => handleDelete(contactDetail.id, contactDetail.level)}> <Delete></Delete>Delete</button>
                                </td>
                            </tr></>)
                        }) : null}
                    </tbody>
                </table>
            </div>

            <ReLoginModal showModal={show}></ReLoginModal>
        </div>
    )
}

export default ContactUsAdmin