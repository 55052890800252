import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isLoggedIn } from '../auth/auth';
import { Navigate, Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import requests from './request';
import axiosProtected from './axios_authenticated_urls'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const PrivateRoutes = () => {
   

    return (
      <>{isLoggedIn() ? <Outlet></Outlet> : <Navigate to="/"></Navigate>}</>  
    );
}

PrivateRoutes.propTypes = propTypes;
PrivateRoutes.defaultProps = defaultProps;
// #endregion

export default PrivateRoutes;