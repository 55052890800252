import React from 'react'

function FisheriesSenario() {
  return (
    <div className='container'>
      <div className='card mt-3'>
        <div className='card-tite'>
          <div className='text-center text-uppercase h4'>Fisheries Scenario </div>
          <div className='card-body'>
            Meghalaya has two drainage systems namely Brahmaputra in the north and Barak in the South. It
            is blessed with a very rich and diverse Inland aquatic ecosystem having a wide variety of aquatic
            resources such as Rivers, Streams, lakes, bheels etc, and with an average rainfall of 1200 mm
            which offers tremendous scope for developing the fisheries sector. The available land in most
            parts of Meghalaya is uneven in terrain that makes it somewhat difficult to develop fisheries on
            commercial lines. However, rain water can be impounded in small ponds for the production of
            fish and inland fisheries therefore does offer a potential that can be successfully exploited by the
            people of the state. It is a powerful income and employment generator as it stimulates growth of
            a number of subsidiary industries and is a source of cheap and nutritious food. Fishery activities
            in the State has come a long way over the years from traditional fish farming practice to adopting
            scientific fish culture techniques which has resulted in Fish production of<strong> 18235.40 MT</strong> from
            water spread area of <strong>4537.87 Hectare</strong>, and <strong>19.4419 millions </strong> of Fish seed production during the
            <strong> FY 2021-22</strong>  through Central scheme viz., Pradhan Mantri Matsya Sampada Yojana (PMMSY)
            and State scheme, Meghalaya State Aquaculture Mission (MSAM 2.0). The State has a huge
            reserve of fisheries resources with potential of attaining self sufficiency in fish and fish seed
            production by bringing more areas under aquaculture. The water bodies also harbour diversified
            native ornamental fishes with large economic importance under natural condition, and as
            recorded 159 fish species endemic to the State, however ornamental fish rearing and breeding is
            almost negligible in the state. The promotion of ornamental fish production in the state would
            encourage entrepreneurs to earn their livelihood from this trade.
          </div>

        </div>
      </div> 

    </div>
  )
}

export default FisheriesSenario