import React from 'react'
import establishing1 from '../../../images/msam/EstablishingSantuaries1.jpg'
import establishing2 from '../../../images/msam/EstablishingSantuaries2.jpg'

function EstablishingSanctuaries() {
  return (
    <>


      <div className='container py-5'>
        <div className='card border-0'>
          <div className='card-header'>
            <h2 className="text-center"> Mini Mission - III: Conservation of Indigenous Fisheries Resources</h2>
          </div>
          <div className='card-body'>

            <div className='row'>
              <div className='col-md-4'>
                <img src={establishing1} alt="" className='img-fluid' ></img>
              </div>
              <div className='col-md-8'>
                <p className='text-justify'>Meghalaya is endowed with rich fisheries resources. It has been documented that the State has 159 fish species endemic to the State. The rivers of Meghalaya are a repository of these aquatic Biodiversity. With the launch of the Meghalaya State Aquaculture Mission, a focus toward conservation of the aquatic biodiversity was given, through the Mass Awareness Campaigns and establishment of Fish Sanctuaries along the stretches of rivers. It has been observed that the initiative was widely accepted and appreciated by the communities dependent on these river stretches as they also reaped secondary benefits of livelihoods through these sanctuaries.</p>
              </div>

              <div className='col-md-8'>
                <p className='mt-3'> 54 (Fifty Four) Fish Sanctuaries have been sanctioned in the State under the MSAM 1.0 and are at various stages of completion. These sanctuaries have become shining examples of community participation and community initiatives. Under MSAM 2.0 it is proposed that more such Sanctuaries would be created along the stretches of Rivers and streams. Possibilities of extending the existing Sanctuaries would also be considered on a case to case basis.</p>
                <p>The outcomes of the Mission were manifold viz:</p>

                <ol>

                  <li>Stock restoration/ rejuvenation of local indigenous fish species in situ</li>
                  <li>Increased capture of food fish in the State</li>
                  <li>Protection of other aquatic fauna like tortoises, frogs etc. and aquatic flora that were thriving in the area</li>
                  <li>Increased rural tourism</li>
                  <li>New Sources of livelihoods and income for the local communities emerged</li>
                </ol>

              </div>
              <div className='col-md-4'>
                <img src={establishing2} alt="" className='img-fluid' ></img>
              </div>
            </div>


            <p className=""><a className='text-reset text-decoration-underline' href="http://msam.nic.in/docs/msam2_0.pdf" rel='noreferrer' target="_blank">For more details download mission document:</a></p>

          </div>
        </div>









      </div>
    </>


  )
}

export default EstablishingSanctuaries